import { Inject, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpParamsOptions
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  DataSyncEventDetailDto,
  DataSyncEventsTableListResponseDto,
  DataSyncEventsTableRequestDto
} from '@ifhms/models/admin';
import { AH_FMS_DATA_SYNC_API_CONFIG } from '../../tokens';
import { SelectListItemDto } from '@common/angular/core/models';

@Injectable()
export class EnterpriseLogsService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_DATA_SYNC_API_CONFIG) private dataSyncApiUrl: string
  ) {}

  getDataSyncEventsTable(
    request: DataSyncEventsTableRequestDto
  ): Observable<DataSyncEventsTableListResponseDto> {
    const httpParams: HttpParamsOptions = {
      fromObject: request as unknown as Record<
      string,
      string | number | boolean
      >
    };

    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<DataSyncEventsTableListResponseDto>(
      `${this.dataSyncApiUrl}/DataSync/Events/Table`,
      options
    );
  }

  getOfflineAccessOperations(): Observable<SelectListItemDto[]> {
    return this.http.get<SelectListItemDto[]>(
      `${this.dataSyncApiUrl}/DataSync/Events/Operations`
    );
  }

  getDataSyncEventDetail(id: string): Observable<DataSyncEventDetailDto> {
    return this.http.get<DataSyncEventDetailDto>(
      `${this.dataSyncApiUrl}/DataSync/Events/${id}`
    );
  }

  refireDataSyncEvent(id: string): Observable<string> {
    return this.http.post<string>(
      `${this.dataSyncApiUrl}/DataSync/Events/${id}/Refire`,
      {}
    );
  }
}
