import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  GroupRoleCreateDto,
  GroupRoleDto,
  GroupRoleListRequestDto,
  GroupRoleListResultDto,
  GroupRoleUpdateDto,
  GroupUserListItemDto,
  GroupUserRoleListItemDto
} from '@ifhms/models/admin';

@Injectable()
export class GroupRoleService {
  constructor(
    @Inject('apiUrl') private apiUrl: string,
    private http: HttpClient
  ) {
  }

  getList(groupId: string): Observable<GroupUserRoleListItemDto[]> {
    return this.http.get<GroupUserRoleListItemDto[]>(`${this.apiUrl}/Group/${groupId}/Roles/SelectList`);
  }

  getTable(groupId: string, request: GroupRoleListRequestDto): Observable<GroupRoleListResultDto> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        ...request
      }
    };

    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<GroupRoleListResultDto>(`${this.apiUrl}/Group/${groupId}/Roles/Table`, options);
  }

  get(groupId: string, id: string): Observable<GroupRoleDto> {
    return this.http.get<GroupRoleDto>(`${this.apiUrl}/Group/${groupId}/Roles/${id}`);
  }

  getRoleUsers(groupId: string, roleId: string): Observable<GroupUserListItemDto[]> {
    return this.http.get<GroupUserListItemDto[]>(`${this.apiUrl}/Group/${groupId}/Roles/Users/${roleId}`);
  }

  create(groupId: string, roleCreate: GroupRoleCreateDto): Observable<GroupRoleDto> {
    return this.http.post<GroupRoleDto>(`${this.apiUrl}/Group/${groupId}/Roles`, roleCreate);
  }

  update(groupId: string, roleUpdate: GroupRoleUpdateDto): Observable<GroupRoleDto> {
    return this.http.put<GroupRoleDto>(`${this.apiUrl}/Group/${groupId}/Roles`, roleUpdate);
  }

  isNameUnique(groupId: string, name: string): Observable<boolean> {
    const httpParams: HttpParamsOptions = {
      fromObject: { name }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<boolean>(`${this.apiUrl}/Group/${groupId}/Roles/IsNameUnique`, options);
  }
}
