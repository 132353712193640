export enum DataSyncEventDirection {
  SqlToCouchbase = 1,
  CouchbaseToSql = 2
}

export const DataSyncEventDirectionLabels: Record<
DataSyncEventDirection,
string
> = {
  [DataSyncEventDirection.SqlToCouchbase]: 'SQL to Couchbase',
  [DataSyncEventDirection.CouchbaseToSql]: 'Couchbase to SQL'
};

export function getDataSyncEventDirectionLabel(
  direction: DataSyncEventDirection
): string {
  return DataSyncEventDirectionLabels[direction];
}
