import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as AcquisitionTypes from './acquisition-types/acquisition-types.reducer';
import * as AgeClassesReducer from './age-classes/age-classes.reducer';
import * as BreedTypesReducer from './breed-types/breed-types.reducer';
import * as BreedsReducer from './breeds/breeds.reducer';
import * as CountriesReducer from './countries/countries.reducer';
import * as DentitionsReducer from './dentitions/dentitions.reducer';
import * as DoseUnitsReducer from './dose-units/dose-units.reducer';
import * as DxGroupsReducer from './dx-groups/dx-groups.reducer';
import * as FacilitiesReducer from './facilities/facilities.reducer';
import * as FeedingProgramsReducer from './feeding-programs/feeding-programs.reducer';
import * as GendersReducer from './genders/genders.reducer';
import * as InjectionLocationsReducer from './injection-locations/injection-locations.reducer';
import * as LabourChargeTypesReducer from './labour-charge-types/labour-charge-types.reducer';
import * as LotOwnersReducer from './lot-owners/lot-owners.reducer';
import * as LotSubGroupReducer from './lot-subgroups/lot-subgroups.reducer';
import * as LotsReducer from './lots/lots.reducer';
import * as ManufacturersReducer from './manufacturers/manufacturers.reducer';
import * as MarketingPlansReducer from './marketing-plans/marketing-plans.reducer';
import * as PensReducer from './pens/pens.reducer';
import * as PricingMethodsReducer from './pricing-methods/pricing-methods.reducer';
import * as ProcurementCodesReducer from './procurement-codes/procurement-codes.reducer';
import * as ProductTypesReducer from './product-types/product-types.reducer';
import * as RiskCategoriesReducer from './risk-categories/risk-categories.reducer';
import * as RoutesReducer from './routes/routes.reducer';
import * as TagColorsReducer from './tag-colors/tag-colors.reducer';
import * as TagLocationsReducer from './tag-locations/tag-locations.reducer';
import * as UnitsOfMeasureReducer from './units-of-measure/units-of-measure.reducer';
import * as VendorsReducer from './vendors/vendors.reducer';
import * as SortTypesReducer from './sort-types/sort-types.reducer';
import * as TxGroupReducer from './tx-groups/tx-groups.reducer';

export const FEATURE_NAME = 'reference-data';

export const selectFeatureState = createFeatureSelector<State>(FEATURE_NAME);

export const reducer: ActionReducerMap<State> = {
  acquisitionTypes: AcquisitionTypes.reducer,
  ageClasses: AgeClassesReducer.reducer,
  breedTypes: BreedTypesReducer.reducer,
  breeds: BreedsReducer.reducer,
  countries: CountriesReducer.reducer,
  dentitions: DentitionsReducer.reducer,
  doseUnits: DoseUnitsReducer.reducer,
  dxGroups: DxGroupsReducer.reducer,
  facilities: FacilitiesReducer.reducer,
  feedingPrograms: FeedingProgramsReducer.reducer,
  genders: GendersReducer.reducer,
  injectionLocations: InjectionLocationsReducer.reducer,
  labourChargeTypes: LabourChargeTypesReducer.reducer,
  lots: LotsReducer.reducer,
  lotOwners: LotOwnersReducer.reducer,
  lotSubGroups: LotSubGroupReducer.reducer,
  manufacturers: ManufacturersReducer.reducer,
  marketingPlans: MarketingPlansReducer.reducer,
  pens: PensReducer.reducer,
  pricingMethods: PricingMethodsReducer.reducer,
  procurementCodes: ProcurementCodesReducer.reducer,
  productTypes: ProductTypesReducer.reducer,
  riskCategories: RiskCategoriesReducer.reducer,
  routes: RoutesReducer.reducer,
  tagColors: TagColorsReducer.reducer,
  tagLocations: TagLocationsReducer.reducer,
  unitsOfMeasure: UnitsOfMeasureReducer.reducer,
  vendors: VendorsReducer.reducer,
  sortTypes: SortTypesReducer.reducer,
  txGroups : TxGroupReducer.reducer
};

export interface State {
  acquisitionTypes: AcquisitionTypes.State;
  ageClasses: AgeClassesReducer.State;
  breedTypes: BreedTypesReducer.State;
  breeds: BreedsReducer.State;
  countries: CountriesReducer.State;
  dentitions: DentitionsReducer.State;
  doseUnits: DoseUnitsReducer.State;
  dxGroups: DxGroupsReducer.State;
  facilities: FacilitiesReducer.State;
  feedingPrograms: FeedingProgramsReducer.State;
  genders: GendersReducer.State;
  injectionLocations: InjectionLocationsReducer.State;
  labourChargeTypes: LabourChargeTypesReducer.State;
  lots: LotsReducer.State,
  lotSubGroups: LotSubGroupReducer.State,
  lotOwners: LotOwnersReducer.State,
  manufacturers: ManufacturersReducer.State;
  marketingPlans: MarketingPlansReducer.State;
  pens: PensReducer.State;
  pricingMethods: PricingMethodsReducer.State;
  procurementCodes: ProcurementCodesReducer.State;
  productTypes: ProductTypesReducer.State;
  riskCategories: RiskCategoriesReducer.State;
  routes: RoutesReducer.State;
  tagColors: TagColorsReducer.State;
  tagLocations: TagLocationsReducer.State;
  unitsOfMeasure: UnitsOfMeasureReducer.State;
  vendors: VendorsReducer.State;
  sortTypes: SortTypesReducer.State;
  txGroups: TxGroupReducer.State;
}
