import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { GroupFacade } from '@ifhms/admin/web/domain/state/group';
import { AdminPermissionsService } from '@ifhms/common/angular/data-access/admin-api';
import { GroupPermissionsService } from '@ifhms/admin/web/domain/permissions';
import { getPreviousValue, transformPermission } from '@common/angular/utils';
import { GroupPermissionsDto } from '@ifhms/models/admin';
import { CommonPermissionsMap } from '@common/angular/permissions';

import { GroupPermissionsActions } from './group-permissions.actions';
import { GroupPermissionsFacade } from './group-permissions.facade';

@Injectable()
export class GroupPermissionsEffects {

  constructor(
    private actions$: Actions,
    private groupFacade: GroupFacade,
    private adminPermissionsService: AdminPermissionsService,
    private groupPermissionsService: GroupPermissionsService,
    private groupPermissionsFacade: GroupPermissionsFacade
  ) {}
  
  getGroupPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupPermissionsActions.getGroupPermissions),
      withLatestFrom(getPreviousValue(this.groupPermissionsFacade.state$), this.groupFacade.groupId$),
      filter(([action, state, groupId]) => {
        const targetGroupId = action.groupId || groupId;
        const isGroupChange = state.groupId && targetGroupId !== state.groupId;

        if (action.forceReload || isGroupChange) return true;

        return !state.loaded && !state.loading;
      }),
      switchMap(([action,, groupId]) => {
        const _groupId = action.groupId || groupId;
        return this.adminPermissionsService.getGroupPermissionsForUser(_groupId).pipe(
          tap(permissions => this.setGroupPermissions(permissions)),
          map((groupPermissions) => GroupPermissionsActions.getGroupPermissionsSuccess({
            groupPermissions,
            groupId
          })),
          catchError(err => of(GroupPermissionsActions.error(err)))
        )
      })
    )
  );

  private setGroupPermissions(permissions: GroupPermissionsDto): void {
    const flatPermissions: CommonPermissionsMap = transformPermission(permissions)!;
    this.groupPermissionsService.setPermissions(flatPermissions);
  }

}
