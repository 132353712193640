import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, filter, map, of, withLatestFrom } from 'rxjs';

import { GroupService } from '@ifhms/common/angular/data-access/admin-api';
import { CompanyFacade } from '@ifhms/admin/web/domain/state/company';
import { GroupDto } from '@ifhms/models/admin';
import { getPreviousValue } from '@common/angular/utils';

import { GroupActions } from './group.actions';
import { GroupFacade } from './group.facade';
import { State } from './group.reducer';

@Injectable()
export class GroupEffects {

  // TODO: change to slug
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupActions.get),
      withLatestFrom(getPreviousValue(this.groupFacade.state$), this.companyFacade.companyId$),
      filter(([action, state]) => {
        const isGroupChange = this.isGroupChange(action.id, state);

        if (action.forceReload || isGroupChange) return true;

        return !state.loaded && !state.loading;
      }),
      exhaustMap(([action]) =>
        this.groupService.get(action.id).pipe(
          map((group: GroupDto) => GroupActions.getSuccess({ group })),
          catchError(() => of(GroupActions.error()))
        )
      )
    )
  );

  getBySlug$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupActions.getBySlug),
      exhaustMap((action) =>
        this.groupService.getBySlug(action.slug).pipe(
          map((group: GroupDto) => {
            return GroupActions.getBySlugSuccess({
              group,
              updateCurrent: action.updateCurrent
            });
          }),
          catchError(() => of(GroupActions.error()))
        )
      )
    )
  );

  getCached$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupActions.get),
      withLatestFrom(getPreviousValue(this.groupFacade.state$)),
      filter(([action, state]) => {
        return !action.forceReload && state.loaded && !this.isGroupChange(action.id, state);
      }),
      map(() => GroupActions.getSuccessCached())
    )
  );

  constructor(
    private actions$: Actions,
    private companyFacade: CompanyFacade,
    private groupFacade: GroupFacade,
    private groupService: GroupService
  ) { }

  // TODO: change to slug
  private isGroupChange(targetId: string, state: State): boolean {
    const currentGroupId = state?.group?.id;
    return !!currentGroupId && currentGroupId !== targetId;
  }

}
