import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { AuthUser } from '../interfaces';
import { authActions } from './auth.actions';
import { AuthState } from './auth.reducer';
import { authQuery } from './auth.selectors';

@Injectable()
export class AuthFacade {

  state$: Observable<AuthState> = this.store.select(authQuery.selectState);
  isAuthComplete$: Observable<boolean> = this.store.select(authQuery.selectIsAuthComplete);
  userId$: Observable<string | null> = this.store.select(authQuery.selectUserId);
  userRoles$: Observable<string[]> = this.store.select(authQuery.selectUserRoles);
  isAuthInProgress$: Observable<boolean> = this.store.select(authQuery.selectIsAuthInProgress);
  isAuthenticated$: Observable<boolean> = this.store.select(authQuery.selectIsAuthenticated);

  constructor(private store: Store<AuthState>) {}

  init(): void {
    this.store.dispatch(authActions.init());
  }

  login(redirectUrl = ''): void {
    this.store.dispatch(authActions.login({ redirectUrl: redirectUrl }));
  }

  logout(): void {
    this.store.dispatch(authActions.logout());
  }

  clearSession(): void {
    this.store.dispatch(authActions.clearSession());
  }

  updateAuthUser(authUser: AuthUser | null): void {
    this.store.dispatch(authActions.updateAuthUser({ authUser }));
  }

  setLoggedInUser(authUser: AuthUser): void {
    this.store.dispatch(authActions.loginSuccess({ authUser }));
  }

}
