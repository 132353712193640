import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AcquisitionTypesEffects } from './+state/acquisition-types/acquisition-types.effects';
import { AcquisitionTypesFacade } from './+state/acquisition-types/acquisition-types.facade';
import { AgeClassesEffects } from './+state/age-classes/age-classes.effects';
import { AgeClassesFacade } from './+state/age-classes/age-classes.facade';
import { BreedTypesEffects } from './+state/breed-types/breed-types.effects';
import { BreedTypesFacade } from './+state/breed-types/breed-types.facade';
import { BreedsEffects } from './+state/breeds/breeds.effects';
import { BreedsFacade } from './+state/breeds/breeds.facade';
import { CountriesEffects } from './+state/countries/countries.effects';
import { CountriesFacade } from './+state/countries/countries.facade';
import { DentitionsEffects } from './+state/dentitions/dentitions.effects';
import { DentitionsFacade } from './+state/dentitions/dentitions.facade';
import { DoseUnitsEffects } from './+state/dose-units/dose-units.effects';
import { DoseUnitsFacade } from './+state/dose-units/dose-units.facade';
import { DxGroupsEffects } from './+state/dx-groups/dx-groups.effects';
import { DxGroupsFacade } from './+state/dx-groups/dx-groups.facade';
import { ReferenceDataEffects } from './+state/effects';
import { ReferenceDataFacade } from './+state/facade';
import { FacilitiesEffects } from './+state/facilities/facilities.effects';
import { FacilitiesFacade } from './+state/facilities/facilities.facade';
import { FeedingProgramsEffects } from './+state/feeding-programs/feeding-programs.effects';
import { FeedingProgramsFacade } from './+state/feeding-programs/feeding-programs.facade';
import { GendersEffects } from './+state/genders/genders.effects';
import { GendersFacade } from './+state/genders/genders.facade';
import { InjectionLocationsEffects } from './+state/injection-locations/injection-locations.effects';
import { InjectionLocationsFacade } from './+state/injection-locations/injection-locations.facade';
import { LabourChargeTypesFacade } from './+state/labour-charge-types/labour-charge-types.facade';
import { LotOwnersEffects } from './+state/lot-owners/lot-owners.effects';
import { LotOwnersFacade } from './+state/lot-owners/lot-owners.facade';
import { LotSubGroupsEffects } from './+state/lot-subgroups/lot-subgroups.effects';
import { LotSubGroupsFacade } from './+state/lot-subgroups/lot-subgroups.facade';
import { LotsEffects } from './+state/lots/lots.effects';
import { LotsFacade } from './+state/lots/lots.facade';
import { ManufacturersEffects } from './+state/manufacturers/manufacturers.effects';
import { ManufacturersFacade } from './+state/manufacturers/manufacturers.facade';
import { MarketingPlansEffects } from './+state/marketing-plans/marketing-plans.effects';
import { MarketingPlansFacade } from './+state/marketing-plans/marketing-plans.facade';
import { PensEffects } from './+state/pens/pens.effects';
import { PensFacade } from './+state/pens/pens.facade';
import { PricingMethodsEffects } from './+state/pricing-methods/pricing-methods.effects';
import { PricingMethodsFacade } from './+state/pricing-methods/pricing-methods.facade';
import { ProcessMethodsFacade } from './+state/process-methods/process-methods.facade';
import { ProcurementCodesEffects } from './+state/procurement-codes/procurement-codes.effects';
import { ProcurementCodesFacade } from './+state/procurement-codes/procurement-codes.facade';
import { ProductTypesEffects } from './+state/product-types/product-types.effects';
import { ProductTypesFacade } from './+state/product-types/product-types.facade';
import { FEATURE_NAME, reducer } from './+state/reducer';
import { RiskCategoriesEffects } from './+state/risk-categories/risk-categories.effects';
import { RiskCategoriesFacade } from './+state/risk-categories/risk-categories.facade';
import { RoutesEffects } from './+state/routes/routes.effects';
import { RoutesFacade } from './+state/routes/routes.facade';
import { TagColorsEffects } from './+state/tag-colors/tag-colors.effects';
import { TagColorsFacade } from './+state/tag-colors/tag-colors.facade';
import { TagLocationsEffects } from './+state/tag-locations/tag-locations.effects';
import { TagLocationsFacade } from './+state/tag-locations/tag-locations.facade';
import { UnitsOfMeasureEffects } from './+state/units-of-measure/units-of-measure.effects';
import { UnitsOfMeasureFacade } from './+state/units-of-measure/units-of-measure.facade';
import { VendorsEffects } from './+state/vendors/vendors.effects';
import { VendorsFacade } from './+state/vendors/vendors.facade';
import { SortTypesFacade } from './+state/sort-types/sort-types.facade';
import { SortTypesEffects } from './+state/sort-types/sort-types.effects';
import { TxGroupsEffects } from './+state/tx-groups/tx-groups.effects';
import { TxGroupsFacade } from './+state/tx-groups/tx-groups.facade';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_NAME, reducer),
    EffectsModule.forFeature([
      AcquisitionTypesEffects,
      AgeClassesEffects,
      BreedTypesEffects,
      BreedsEffects,
      CountriesEffects,
      DentitionsEffects,
      DoseUnitsEffects,
      DxGroupsEffects,
      GendersEffects,
      FacilitiesEffects,
      FeedingProgramsEffects,
      InjectionLocationsEffects,
      LotsEffects,
      LotOwnersEffects,
      LotSubGroupsEffects,
      ManufacturersEffects,
      MarketingPlansEffects,
      PensEffects,
      PricingMethodsEffects,
      ProcurementCodesEffects,
      ProductTypesEffects,
      ReferenceDataEffects,
      RiskCategoriesEffects,
      RoutesEffects,
      TagColorsEffects,
      TagLocationsEffects,
      UnitsOfMeasureEffects,
      VendorsEffects,
      SortTypesEffects,
      TxGroupsEffects
    ])
  ],
  providers: [
    AcquisitionTypesFacade,
    AgeClassesFacade,
    BreedTypesFacade,
    BreedsFacade,
    CountriesFacade,
    DentitionsFacade,
    DoseUnitsFacade,
    DxGroupsFacade,
    GendersFacade,
    FacilitiesFacade,
    FeedingProgramsFacade,
    InjectionLocationsFacade,
    LabourChargeTypesFacade,
    LotsFacade,
    LotSubGroupsFacade,
    LotOwnersFacade,
    SortTypesFacade,
    ManufacturersFacade,
    MarketingPlansFacade,
    PensFacade,
    PricingMethodsFacade,
    ProcessMethodsFacade,
    ProcurementCodesFacade,
    ProductTypesFacade,
    ReferenceDataFacade,
    RiskCategoriesFacade,
    RoutesFacade,
    TagColorsFacade,
    TagLocationsFacade,
    TxGroupsFacade,
    UnitsOfMeasureFacade,
    VendorsFacade
  ]
})
export class IfhmsAdminWebDomainStateReferenceDataModule {
}
