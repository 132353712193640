import { InjectionToken } from '@angular/core';

export interface AhFmsAdminApiConfig {
  apiUrl: string;
}

export const AH_FMS_ADMIN_API_CONFIG = new InjectionToken<AhFmsAdminApiConfig>(
  'AH_FMS_ADMIN_API_CONFIG'
);
export const AH_FMS_ADMIN_SYSTEM_API_URL =
  new InjectionToken<AhFmsAdminApiConfig>('AH_FMS_ADMIN_SYSTEM_API_URL');
export const AH_FMS_DATA_SYNC_API_CONFIG =
  new InjectionToken<string>('AH_FMS_ADMIN_API_CONFIG');
