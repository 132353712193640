import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GroupOperationCompanySlugDto } from '@ifhms/models/admin';

import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class GroupOperationsService {
  constructor(
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string,
    private http: HttpClient
  ) { }

  getOperations(id: string): Observable<GroupOperationCompanySlugDto> {
    return this.http.get<GroupOperationCompanySlugDto>(`${this.apiUrl}/Groups/${id}/Operations`);
  }

  getOperationsBySlug(slug: string): Observable<GroupOperationCompanySlugDto> {
    return this.http.get<GroupOperationCompanySlugDto>(`${this.apiUrl}/Groups/slug/${slug}/Operations`);
  }

}