import { createAction, props } from '@ngrx/store';
import { GroupDto } from '@ifhms/models/admin';

const NAMESPACE = '[GROUP]';

const get = createAction(
  `${NAMESPACE} Get`,
  props<{ id: string, forceReload: boolean }>()
);

const getSuccess = createAction(
  `${NAMESPACE} Get Success`,
  props<{ group: GroupDto }>()
);

const getBySlug = createAction(
  `${NAMESPACE} Get By Slug`,
  props<{ slug: string; updateCurrent: boolean }>()
);

const getBySlugSuccess = createAction(
  `${NAMESPACE} Get By Slug Success`,
  props<{ group: GroupDto; updateCurrent: boolean }>()
);

const getSuccessCached = createAction(`${NAMESPACE} Get Success Cached`);

const error = createAction(`${NAMESPACE} Error`);

const reset = createAction(`${NAMESPACE} Reset`);

export const GroupActions = {
  get,
  getSuccess,
  getBySlug,
  getBySlugSuccess,
  getSuccessCached,
  error,
  reset
};
