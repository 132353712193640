import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GroupDto, GroupListItemDto } from '@ifhms/models/admin';

import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class GroupService {
  constructor(
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string,
    private http: HttpClient
  ) { }

  get(id: string): Observable<GroupDto> {
    return this.http.get<GroupDto>(`${this.apiUrl}/Groups/${id}`);
  }

  getAllUserGroups(): Observable<GroupListItemDto[]> {
    return this.http.get<GroupListItemDto[]>(`${this.apiUrl}/Groups`);
  }

  getBySlug(slug: string): Observable<GroupDto> {
    return this.http.get<GroupDto>(`${this.apiUrl}/Groups/slug/${slug}`);
  }

}
