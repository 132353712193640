import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';
import {
  FY3000ListItemDto,
  FY3000ModalSettingsDto,
  FY3000SettingsDto
} from '@ifhms/models/admin';

@Injectable()
export class FeedlotSettingsFY3000Service {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {}

  getTable(operationId: string): Observable<FY3000ListItemDto[]> {
    return this.http.get<FY3000ListItemDto[]>(
      `${this.apiUrl}/Operation/${operationId}/FY3000/History`
    );
  }

  getModalSettings(operationId: string): Observable<FY3000ModalSettingsDto> {
    return this.http.get<FY3000ModalSettingsDto>(
      `${this.apiUrl}/Operation/${operationId}/FY3000/Settings`
    );
  }

  updateModalSettings(
    modalSettings: FY3000ModalSettingsDto,
    operationId: string
  ): Observable<FY3000ModalSettingsDto> {
    return this.http.put<FY3000ModalSettingsDto>(
      `${this.apiUrl}/Operation/${operationId}/FY3000/Settings`,
      modalSettings
    );
  }

  getFY3000Settings(operationId: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.apiUrl}/Operation/${operationId}/FY3000/FY3000`
    );
  }

  updateFY3000AccountSettings(
    operationId: string,
    settings: FY3000SettingsDto
  ): Observable<boolean> {
    return this.http.put<boolean>(
      `${this.apiUrl}/Operation/${operationId}/FY3000/FY3000/${settings.isFY3000Enabled}`,
      settings
    );
  }

  generateUsageReport(
    startDate: string,
    endDate: string,
    operationId: string
  ): Observable<any> {
    const options = {
      startDate,
      endDate
    };

    return this.http.put(
      `${this.apiUrl}/Operation/${operationId}/FY3000/ExportVetUsage`,
      options,
      { responseType: 'arraybuffer', observe: 'response'  }
    );
  }

  generateMoveReport(
    startDate: string,
    endDate: string,
    operationId: string
  ): Observable<any> {
    const options = {
      startDate,
      endDate
    };
    return this.http.put(
      `${this.apiUrl}/Operation/${operationId}/FY3000/ExportVetMoves`,
      options,
      { responseType: 'arraybuffer', observe: 'response' }
    );
  }
}
