<input
  class="p-inputtext p-component p-element w-full"
  type="text"
  [ngClass]="to['inputClassNames']"
  [class.ng-invalid]="formControl.invalid"
  [formControl]="formControl"
  [formlyAttributes]="field"
  [placeholder]="(props['placeholder$'] | async) || props['placeholder']"
  [attr.data-ele]="props['attrDataEle'] || key"
  [maxlength]="props['maxLength'] ? props['maxLength'] : $any(null)"
  [minlength]="props['minLength'] ? props['minLength'] : $any(null)"
  [id]="id + '_input'"
  [pTooltip]="props['tooltip'] || null"
  (blur)="props.blur?.(field, $event)"
/>
