import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { OperationCreateDto, OperationDto, OperationListItemDto, OperationUpdateDto } from '@ifhms/models/admin';
import { SelectListItemWithTranslationDto } from '@ifhms/models/shared';
import { Observable } from 'rxjs';
import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class OperationsService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {
  }

  getTypes(): Observable<SelectListItemWithTranslationDto[]> {
    return this.http.get<SelectListItemWithTranslationDto[]>(`${this.apiUrl}/Operations/Types`);
  }

  getAll(companyId: string): Observable<OperationListItemDto[]> {
    const httpParams: HttpParamsOptions = {
      fromObject: { companyId }
    };
    const options = {
      params: new HttpParams(httpParams)
    };
    return this.http.get<OperationListItemDto[]>(`${this.apiUrl}/Operations`, options);
  }

  get(operationId: string): Observable<OperationDto> {
    return this.http.get<OperationDto>(`${this.apiUrl}/Operations/${operationId}`);
  }

  // TODO: update this api to also return the list of module enablement settings and there values
  getBySlug(slug: string): Observable<OperationDto> {
    return this.http.get<OperationDto>(`${this.apiUrl}/Operations/Slug/${slug}`);
  }

  create(companyId: string, dto: OperationCreateDto): Observable<OperationListItemDto[]> {
    return this.http.post<OperationListItemDto[]>(`${this.apiUrl}/Operations/${companyId}`, dto);
  }

  update(operationId: string, dto: OperationUpdateDto): Observable<OperationDto> {
    return this.http.put<OperationDto>(`${this.apiUrl}/Operations/${operationId}`, dto);
  }

  isOperationCreateNameValid(companyId: string, operationName: string): Observable<boolean> {
    const params = { operationName };
    return this.http.get<boolean>(`${this.apiUrl}/Operations/${companyId}/IsOperationCreateNameValid`, { params });
  }

  isOperationUpdateNameValid(operationId: string, operationName: string): Observable<boolean> {
    const params = { operationName };
    return this.http.get<boolean>(`${this.apiUrl}/Operations/${operationId}/IsOperationUpdateNameValid`, { params });
  }
}
