import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ProcurementCodeEditDto, ProcurementCodesListResultDto } from '@ifhms/models/admin';

import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class ProcurementCodesListService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {}

  getProcurementCodesList(): Observable<ProcurementCodesListResultDto[]> {
    return this.http.get<ProcurementCodesListResultDto[]>(
      `${this.apiUrl}/Reference/ProcurementCodes/List`
    );
  }

  editProcurementCode(
    procurementCode: ProcurementCodeEditDto
  ): Observable<ProcurementCodesListResultDto> {
    return this.http.put<ProcurementCodesListResultDto>(
      `${this.apiUrl}/Reference/ProcurementCodes/${procurementCode.id}`,
      procurementCode
    );
  }

  newProcurementCode(
    procurementCode: ProcurementCodeEditDto
  ): Observable<ProcurementCodesListResultDto> {
    return this.http.post<ProcurementCodesListResultDto>(
      `${this.apiUrl}/Reference/ProcurementCodes`,
      procurementCode
    );
  }

  activateCode(
    procurementCodeId: string
  ): Observable<ProcurementCodesListResultDto[]> {
    return this.http.put<ProcurementCodesListResultDto[]>(
      `${this.apiUrl}/Reference/ProcurementCodes/${procurementCodeId}/Activate`,
      {}
    );
  }

  deactivateCode(
    procurementCodeId: string
  ): Observable<ProcurementCodesListResultDto[]> {
    return this.http.put<ProcurementCodesListResultDto[]>(
      `${this.apiUrl}/Reference/ProcurementCodes/${procurementCodeId}/Deactivate`,
      {}
    );
  }
}
