import { createSelector } from '@ngrx/store';

import { selectFeatureState, State } from '../reducer';
import * as reducer from './procurement-codes.reducer';

const selectState = createSelector(
  selectFeatureState, (state: State) => state.procurementCodes
);

const selectItems = createSelector(selectState, reducer.getAll);
const selectLoaded = createSelector(selectState, reducer.getLoaded);

export const ProcurementCodesSelectors = {
  selectItems,
  selectLoaded
};
