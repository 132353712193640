import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonAngularPermissionsModule } from '@common/angular/permissions';
import { FormlyModule } from '@ngx-formly/core';

import {
  ChatPermissionFormGroupComponent,
  FrmPermissionFormGroupComponent,
  ModuleAccessFormGroupComponent,
  OperationPermissionFormGroupComponent,
  ProcurementPermissionFormGroupComponent
} from './components';
import {
  CompanyHasPermissionDirective,
  EnterpriseHasPermissionDirective,
  OperationHasPermissionDirective
} from './directives';
import { OperationPermissionGuard } from './guards';
import { PermissionFieldConfigService } from './services';

@NgModule({
  imports: [
    CommonModule,
    CommonAngularPermissionsModule,
    FormsModule,
    ReactiveFormsModule,
    FormlyModule
  ],
  declarations: [
    CompanyHasPermissionDirective,
    EnterpriseHasPermissionDirective,
    OperationHasPermissionDirective,
    FrmPermissionFormGroupComponent,
    ProcurementPermissionFormGroupComponent,
    ChatPermissionFormGroupComponent,
    ModuleAccessFormGroupComponent,
    OperationPermissionFormGroupComponent
  ],
  providers: [
    OperationPermissionGuard,
    PermissionFieldConfigService
  ],
  exports: [
    CompanyHasPermissionDirective,
    EnterpriseHasPermissionDirective,
    OperationHasPermissionDirective
  ]
})
export class IfhmsAdminWebDomainPermissionsModule {
}
