import { createReducer } from '@ngrx/store';
import { SersiSelectListItem } from '@sersi/angular/formly/core';

const DEFAULT_LIST: SersiSelectListItem[] = [
  {
    id: 12,
    description: 'Animal Alert',
    code: 'AA'
  },
  {
    id: 10,
    description: 'Animal Transfer',
    code: 'AT'
  },
  {
    id: 1,
    description: 'Arrival',
    code: 'AR'
  },
  {
    id: 9,
    description: 'Lot Transfer',
    code: 'LT'
  },
  {
    id: 8,
    description: 'Movement',
    code: 'MO'
  },
  {
    id: 5,
    description: 'Post Mortem',
    code: 'PM'
  },
  {
    id: 6,
    description: 'Rail',
    code: 'RA'
  },
  {
    id: 3,
    description: 'Rehandling',
    code: 'RE'
  },
  {
    id: 7,
    description: 'Shipment',
    code: 'SH'
  },
  {
    id: 11,
    description: 'Transfer In',
    code: 'TI'
  },
  {
    id: 4,
    description: 'Treatment',
    code: 'TX'
  },
  {
    id: 13,
    description: 'Transfer Out',
    code: 'TO'
  }
];

export interface State {
  items: SersiSelectListItem[];
  loaded: boolean;
}

const initialState: State = {
  items: DEFAULT_LIST,
  loaded: true
};

export const reducer = createReducer(
  initialState
);

export const getItems = (state: State): SersiSelectListItem[] => state.items;
export const getLoaded = (state: State): boolean => state.loaded;

