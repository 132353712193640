import { GroupFeaturePermission } from '@ifhms/admin/web/domain/permissions';
import { SersiNavListItem } from '@sersi/angular/ui/side-nav';

export const getGroupNavigationMenuItems = (
  groupSlug: string
): SersiNavListItem[] => [
  {
    key: 'operations',
    icon: 'foundation',
    active: false,
    isVisible: false,
    routerLink: [`/group/${groupSlug}/operations`]
  },
  {
    key: 'user-management',
    icon: 'person_outline',
    active: false,
    isVisible: false,
    requiredPermission: GroupFeaturePermission.UserManagement,
    subItems: [
      {
        key: 'users',
        routerLink: [`/group/${groupSlug}/user-management/users`]
      },
      {
        key: 'roles',
        routerLink: [`/group/${groupSlug}/user-management/roles`]
      }
    ]
  }
];
