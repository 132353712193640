import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CompanyPermissionsDto, EnterprisePermissionsDto, GroupPermissionsDto } from '@ifhms/models/admin';
import { OperationPermissionsDto } from '@ifhms/models/shared';
import { Observable } from 'rxjs';
import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable({ providedIn: 'root' })
export class AdminPermissionsService {

  constructor(
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string,
    private http: HttpClient
  ) {
  }

  getEnterprisePermissionsForUser(): Observable<EnterprisePermissionsDto> {
    return this.http.get<EnterprisePermissionsDto>(`${this.apiUrl}/Enterprise/Permissions`);
  }

  getCompanyPermissionsForUser(companyId: string): Observable<CompanyPermissionsDto> {
    return this.http.get<CompanyPermissionsDto>(`${this.apiUrl}/Company/${companyId}/Permissions`);
  }

  getOperationPermissionsForUser(operationId: string): Observable<OperationPermissionsDto> {
    return this.http.get<OperationPermissionsDto>(`${this.apiUrl}/Operation/${operationId}/Permissions`);
  }

  getGroupPermissionsForUser(groupId: string): Observable<GroupPermissionsDto> {
    return this.http.get<GroupPermissionsDto>(`${this.apiUrl}/Groups/${groupId}/Permissions`);
  }
}
