import { GroupPermissionsDto } from '@ifhms/models/admin';
import { createAction, props } from '@ngrx/store';

const NAMESPACE = '[Group Permissions]';

const getGroupPermissions = createAction(
  `${NAMESPACE} getGroupPermissions`,
  props<{ forceReload: boolean, groupId?: string }>()
);
const getGroupPermissionsSuccess = createAction(`${NAMESPACE} Get Group Permissions Success`,
  props<{ groupPermissions: GroupPermissionsDto; groupId: string }>());

const reset = createAction(`${NAMESPACE} Reset`);

const error = createAction(`${NAMESPACE} Error`, props<{ error: any }>());

export const GroupPermissionsActions = {
  getGroupPermissions,
  getGroupPermissionsSuccess,
  reset,
  error
};
