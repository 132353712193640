import { Inject, Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import {
  DataMigrationCollectionStatusDto,
  DataMigrationFeedlotStatusDto
} from '@ifhms/models/admin';
import * as signalR from '@microsoft/signalr';
import {
  AH_DATA_SYNC_SIGNALR_URL,
  MessageHubService
} from '@ifhms/common/angular/api/message-hub';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class CouchbaseMigrationProgressHubService extends MessageHubService {
  private readonly _connectionState =
    new BehaviorSubject<signalR.HubConnectionState>(
      signalR.HubConnectionState.Disconnected
    );
  private readonly _feedlotProgress =
    new BehaviorSubject<DataMigrationFeedlotStatusDto | null>(null);
  private readonly _collectionProgress = new BehaviorSubject<
  DataMigrationCollectionStatusDto[]
  >([]);

  public readonly connectionState$ = this._connectionState.asObservable();
  public readonly feedlotProgress$ = this._feedlotProgress.asObservable();
  public readonly collectionProgress$ = this._collectionProgress.asObservable();

  constructor(@Inject(AH_DATA_SYNC_SIGNALR_URL) baseSignalRUrl: string) {
    const hubUrl = `${baseSignalRUrl}/MigrationProgress`;
    super(hubUrl);
    this.initialize();
  }

  public async initialize(): Promise<boolean> {
    try {
      await firstValueFrom(this.ensureHubConnection());
      this.registerEventHandlers();
      this._connectionState.next(signalR.HubConnectionState.Connected);

      this.hubConnection.onclose(() => {
        this._connectionState.next(signalR.HubConnectionState.Disconnected);
      });

      return true;
    } catch (error) {
      console.error('Failed to initialize hub connection:', error);
      this._connectionState.next(signalR.HubConnectionState.Disconnected);
      return false;
    }
  }

  protected registerEventHandlers(): void {
    const handlers = {
      UpdateFeedlotProgress: this.handleFeedlotProgress,
      UpdateCollectionProgress: this.handleCollectionProgress
    };

    Object.entries(handlers).forEach(([event, handler]) => {
      this.hubConnection?.on(event, handler);
    });
  }

  private handleFeedlotProgress = (
    progress: DataMigrationFeedlotStatusDto
  ): void => {
    console.log('Received feedlot progress:', progress);
    this._feedlotProgress.next(progress);
  };

  private handleCollectionProgress = (
    progress: DataMigrationCollectionStatusDto
  ): void => {
    console.log('Received collection progress:', progress);
    const currentProgress = this._collectionProgress.value;
    const existingIndex = currentProgress.findIndex(
      (p) => p.collectionName === progress.collectionName
    );

    if (existingIndex !== -1) {
      currentProgress[existingIndex] = progress;
    } else {
      currentProgress.push(progress);
    }

    this._collectionProgress.next([...currentProgress]);
  };

  public reset(): void {
    this._feedlotProgress.next(null);
    this._collectionProgress.next([]);
  }
}
