import { createReducer, on } from '@ngrx/store';

import { GroupListItemDto } from '@ifhms/models/admin';

import { GroupsActions } from './groups.actions';

export const FEATURE_NAME = 'groups';

export interface State {
  userGroups: GroupListItemDto[];
  loading: boolean;
  loaded: boolean;
}

const initialState: State = {
  userGroups: [],
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,

  on(GroupsActions.getAllUserGroups, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),

  on(GroupsActions.getAllUserGroupsSuccess, (state, action) => ({
    ...state,
    userGroups: action.groups,
    loading: false,
    loaded: true
  })),

  on(GroupsActions.getAllUserGroupsSuccessCached, (state) => ({
    ...state,
    loading: false,
    loaded: true
  })),

  on(GroupsActions.error, GroupsActions.reset, () => initialState)
)

export const getAllUserGroups = (state: State): GroupListItemDto[] => state.userGroups;
export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
