import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  LotCreateDto,
  LotDto,
  LotListRequestDto,
  LotListResultDto,
  LotTreatmentProtocolLotSettingsDto,
  LotUpdateDto
} from '@ifhms/models/admin';

import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class LotManagementService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private readonly apiUrl: string
  ) {}

  get(operationId: string, lotId: string): Observable<LotDto> {
    return this.http.get<LotDto>(
      `${this.apiUrl}/Operation/${operationId}/LotManagement/${lotId}`
    );
  }

  getTreatmentProtocols(operationId: string, txGroupId: string): Observable<LotTreatmentProtocolLotSettingsDto> {
    return this.http.get<LotTreatmentProtocolLotSettingsDto>(`${this.apiUrl}/Operation/${operationId}/LotManagement/TxLevels/${txGroupId}`);
  }

  getTreatmentProtocolsByLotId(operationId: string, lotId: string, txGroupId: string): Observable<LotTreatmentProtocolLotSettingsDto> {
    return this.http.get<LotTreatmentProtocolLotSettingsDto>(`${this.apiUrl}/Operation/${operationId}/LotManagement/${lotId}/TxLevels/${txGroupId}`);
  }

  create(operationId: string, dto: LotCreateDto): Observable<LotDto> {
    return this.http.post<LotDto>(`${this.apiUrl}/Operation/${operationId}/LotManagement`, dto);
  }

  update(operationId: string, dto: LotUpdateDto): Observable<LotDto> {
    return this.http.put<LotDto>(`${this.apiUrl}/Operation/${operationId}/LotManagement`, dto);
  }

  getList(operationId: string, request: LotListRequestDto): Observable<LotListResultDto> {
    const params = new HttpParams({ fromObject: request as unknown as Record<string, any> });

    return this.http.get<LotListResultDto>(`${this.apiUrl}/Operation/${operationId}/LotManagement`, { params });
  }

  isLotNumberValid(operationId: string, lotNumber: string): Observable<boolean> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        lotNumber: lotNumber.toString()
      }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<boolean>(`${this.apiUrl}/Operation/${operationId}/LotManagement/IsLotNumberValid`, options);
  }

  isSubGroupNameValid(operationId: string, lotId: string, subGroupName: string): Observable<boolean> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        subGroupName: subGroupName.toString(),
        lotId: lotId
      }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<boolean>(`${this.apiUrl}/Operation/${operationId}/LotManagement/IsSubGroupNameValid`, options);
  }

}
