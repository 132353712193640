<ng-container *ngIf="props['inline']; else fullMessage">
    <p-message [severity]="props['severity']" [text]="(props['message$'] | async) || ''"></p-message>
</ng-container>

<ng-template #fullMessage>
    <p-messages
      [value]="[{
        severity: props['severity'],
        summary: props['title$'] ? (props['title$'] | async) : props['title'],
        detail: props['message$'] ? (props['message$'] | async) : props['message'],
        closable: false
      }]"
      [enableService]="false"
      [closable]="props['closable']"
    />
</ng-template>