export enum DataSyncEventsSortBy {
  TransactionDateAscending = 1,
  TransactionDateDescending = 2,
  StatusAscending = 3,
  StatusDescending = 4,
  FeedlotAscending = 5,
  FeedlotDescending = 6,
  CollectionAscending = 7,
  CollectionDescending = 8,
  DurationAscending = 9,
  DurationDescending = 10
}
