import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';

import { ProcurementCodesListResultDto } from '@ifhms/models/admin';
import { SelectListItemDto } from '@ifhms/models/shared';
import { ProcurementCodesListService } from '@ifhms/common/angular/data-access/admin-api';

import { ProcurementCodesActions } from './procurement-codes.actions';

@Injectable()
export class ProcurementCodesEffects {
  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProcurementCodesActions.get),
      exhaustMap(() => {
        return this.procurementListCodesService.getProcurementCodesList().pipe(
          map(codes => this.transformProcurementCodes(codes)),
          map((items: SelectListItemDto[]) => {
            if (!items) return ProcurementCodesActions.error;
            return ProcurementCodesActions.getSuccess({ items });
          }),
          catchError(() => of(ProcurementCodesActions.error()))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private procurementListCodesService: ProcurementCodesListService
  ) {}

  private transformProcurementCodes(items: ProcurementCodesListResultDto[]): SelectListItemDto[] {
    return items
      .filter(item => item.id)
      .map(item => ({
        id: item.id!,
        code: item.code,
        description: item.description,
        isActive: item.isActive
      }));
  }

}
