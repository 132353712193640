import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, filter, map, of, withLatestFrom } from 'rxjs';

import { GroupService } from '@ifhms/common/angular/data-access/admin-api';
import { GroupListItemDto } from '@ifhms/models/admin';
import { getPreviousValue } from '@common/angular/utils';

import { GroupsActions } from './groups.actions';
import { GroupsFacade } from './groups.facade';

@Injectable()
export class GroupsEffects {

  getAllUserGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsActions.getAllUserGroups),
      withLatestFrom(getPreviousValue(this.groupFacade.state$)),
      filter(([action, state]) => {
        if (action.forceReload) return true;
        return !state.loaded && !state.loading;
      }),
      exhaustMap(() =>
        this.groupService.getAllUserGroups().pipe(
          map((groups: GroupListItemDto[]) => GroupsActions.getAllUserGroupsSuccess({ groups })),
          catchError(() => of(GroupsActions.error()))
        )
      )
    )
  );

  getAllUserGroupsCached$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GroupsActions.getAllUserGroups),
      withLatestFrom(getPreviousValue(this.groupFacade.state$)),
      filter(([action, state]) => !action.forceReload && state.loaded),
      map(() => GroupsActions.getAllUserGroupsSuccessCached())
    )
  );

  constructor(
    private actions$: Actions,
    private groupFacade: GroupsFacade,
    private groupService: GroupService
  ) {}

}
