import { Inject, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpParamsOptions
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  DataMigrationHistoryDto,
  DataMigrationTableListResponseDto,
  DataMigrationTableRequestDto,
  DeviceDto,
  DeviceManagementTableListResponseDto,
  DeviceManagementTableRequestDto
} from '@ifhms/models/admin';
import {
  AH_FMS_ADMIN_API_CONFIG,
  AH_FMS_DATA_SYNC_API_CONFIG
} from '../../tokens';

@Injectable()
export class OfflineAccessManagementService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_DATA_SYNC_API_CONFIG) private dataSyncApiUrl: string,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private adminApiUrl: string
  ) {}

  getMigrationTable(
    operationId: string,
    request: DataMigrationTableRequestDto
  ): Observable<DataMigrationTableListResponseDto> {
    const httpParams: HttpParamsOptions = {
      fromObject: request as unknown as Record<string, any>
    };

    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<DataMigrationTableListResponseDto>(
      `${this.dataSyncApiUrl}/Operation/${operationId}/DataSync/Migration/Table`,
      options
    );
  }

  getDeviceTable(
    operationId: string,
    request: DeviceManagementTableRequestDto
  ): Observable<DeviceManagementTableListResponseDto> {
    const httpParams: HttpParamsOptions = {
      fromObject: request as unknown as Record<string, any>
    };

    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<DeviceManagementTableListResponseDto>(
      `${this.adminApiUrl}/Operation/${operationId}/OfflineAccess/DeviceManagement/Table`,
      options
    );
  }

  createMigration(
    operationId: string,
    overwriteExistingData = false
  ): Observable<string> {
    return this.http.get<string>(
      `${this.dataSyncApiUrl}/Operation/${operationId}/DataSync/Migration/Start?overwriteExistingData=${overwriteExistingData}`
    );
  }

  getMigrationDetail(
    operationId: string,
    migrationId: string
  ): Observable<DataMigrationHistoryDto> {
    return this.http.get<DataMigrationHistoryDto>(
      `${this.dataSyncApiUrl}/Operation/${operationId}/DataSync/Migration/Detail/${migrationId}`,
      {}
    );
  }

  getDeviceDetail(
    operationId: string,
    deviceId: string
  ): Observable<DeviceDto> {
    return this.http.get<DeviceDto>(
      `${this.adminApiUrl}/Operation/${operationId}/OfflineAccess/DeviceManagement/Detail/${deviceId}`,
      {}
    );
  }

  revokeDevice(operationId: string, deviceId: string): Observable<DeviceDto> {
    return this.http.post<DeviceDto>(
      `${this.adminApiUrl}/Operation/${operationId}/OfflineAccess/DeviceManagement/RevokeDevice/${deviceId}`,
      {}
    );
  }

  updateDeviceStatus(
    operationId: string,
    deviceId: string,
    isEnable: boolean
  ): Observable<DeviceDto> {
    let action = 'EnableDevice';
    if (!isEnable) {
      action = 'DisableDevice';
    }

    return this.http.post<DeviceDto>(
      `${this.adminApiUrl}/Operation/${operationId}/OfflineAccess/DeviceManagement/${action}/${deviceId}`,
      {}
    );
  }

  cancelMigration(operationId: string, migrationId: string): Observable<any> {
    return this.http.post<any>(
      `${this.dataSyncApiUrl}/Operation/${operationId}/DataSync/Migration/Cancel/${migrationId}`,
      {}
    );
  }

  clearMigrationData(operationId: string): Observable<any> {
    return this.http.put<any>(
      `${this.dataSyncApiUrl}/Operation/${operationId}/DataSync/Migration/Clear`,
      {}
    );
  }

  getIsDataMigrated(operationId: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.dataSyncApiUrl}/Operation/${operationId}/DataSync/Migration/IsMigrated`
    );
  }

  getIsDataMigrationInProgress(operationId: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.dataSyncApiUrl}/Operation/${operationId}/DataSync/Migration/IsMigrationInProgress`
    );
  }
}
