import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { GroupUserInviteDto, GroupUserListItemDto, GroupUserUpdateDto } from '@ifhms/models/admin';
import { Observable } from 'rxjs';

@Injectable()
export class GroupUserService {
  constructor(@Inject('apiUrl') private apiUrl: string, private http: HttpClient) {
  }

  getList(groupId: string): Observable<GroupUserListItemDto[]> {
    return this.http.get<GroupUserListItemDto[]>(`${this.apiUrl}/Group/${groupId}/Users/List`);
  }

  invite(groupId: string, groupUserUpdateDto: GroupUserInviteDto): Observable<GroupUserListItemDto[]> {
    return this.http.put<GroupUserListItemDto[]>(`${this.apiUrl}/Group/${groupId}/Users/Invite`, groupUserUpdateDto);
  }

  update(groupId: string, groupUserUpdateDto: GroupUserUpdateDto): Observable<GroupUserListItemDto[]> {
    return this.http.put<GroupUserListItemDto[]>(`${this.apiUrl}/Group/${groupId}/Users`, groupUserUpdateDto);
  }

  revoke(groupId: string, groupUserId: string): Observable<GroupUserListItemDto[]> {
    return this.http.put<GroupUserListItemDto[]>(`${this.apiUrl}/Group/${groupId}/Users/${groupUserId}/Revoke`, {});
  }

  isEmailValid(groupId: string, email: string): Observable<boolean> {
    const httpParams: HttpParamsOptions = {
      fromObject: { email }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<boolean>(`${this.apiUrl}/Group/${groupId}/Users/IsEmailUnique`, options);
  }
}
