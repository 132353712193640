import { InjectionToken } from '@angular/core';
import { RoleAccessConfig } from './auth-roles.type';

export abstract class AuthStorage {
  abstract getItem(key: string): string | null;

  abstract removeItem(key: string): void;

  abstract setItem(key: string, data: string): void;
}

export interface AuthModuleConfig {
  clientId: string;
  baseAppRoutePath?: string;
  baseAuthRoutePath?: string;
  loginRoutePath?: string;
  useLocaleStorage?: boolean;
  sendAccessToken?: boolean;
  sessionChecksEnabled?: boolean;
  sessionRefreshEnabled?: boolean;
  scope?: string;
  forceUserLogin?: boolean;
  silentRenew?: boolean;
  useRefreshToken?: boolean;
  silentRefreshRedirectUri?: string;
  restrictedRoleAccess?: RoleAccessConfig;
}

export interface AuthServiceConfig extends AuthModuleConfig {
  baseAppRoutePath: string;
  baseAuthRoutePath: string;
  loginRoutePath: string;
  storageReturnUrlKey: string;
  storageLoginRetriesCountKey: string;
  silentRefreshRedirectUri: string;
}

export const AUTH_CONFIG_SERVER_URL = new InjectionToken<string>('AUTH_CONFIG_SERVER_URL');
export const AUTH_STORAGE = new InjectionToken<AuthStorage>('AUTH_STORE');
export const AUTH_CONFIG = new InjectionToken<AuthModuleConfig>('AUTH_CONFIG');
export const AUTH_CONFIG_APP_ORIGIN = new InjectionToken<string>('AUTH_CONFIG_APP_ORIGIN');
export const AUTH_CONFIG_API_URLS = new InjectionToken<string>('AUTH_CONFIG_API_URLS');
export const AUTH_CONFIG_APP_API_URL = 'apiUrl';
export const AUTH_CONFIG_POST_LOGOUT_URL = new InjectionToken<string>('AUTH_CONFIG_POST_LOGOUT_URL');
