import { createSelector } from '@ngrx/store';
import { selectFeatureState, State } from '../permissions.reducer';

import * as PermissionsReducer from './group-permissions.reducer';

const selectState = createSelector(
  selectFeatureState,
  (state: State) => state.groupPermissions
);

const selectGroupPermissions = createSelector(selectState, PermissionsReducer.getGroupPermissions);
const selectLoading = createSelector(selectState, PermissionsReducer.getLoading);
const selectLoaded = createSelector(selectState, PermissionsReducer.getLoaded);

export const PermissionsSelector = {
  selectState,
  selectLoading,
  selectLoaded,
  selectGroupPermissions
};
