import { Inject, Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import * as signalR from '@microsoft/signalr';
import {
  AH_DATA_SYNC_SIGNALR_URL,
  MessageHubService
} from '@ifhms/common/angular/api/message-hub';
import { DataSyncEventDetailDto } from '@ifhms/models/admin';

@UntilDestroy()
@Injectable()
export class DataSyncEventsHubService extends MessageHubService {
  private readonly _connectionState =
    new BehaviorSubject<signalR.HubConnectionState>(
      signalR.HubConnectionState.Disconnected
    );
  private readonly _dataSyncEvents =
    new BehaviorSubject<DataSyncEventDetailDto | null>(null);

  public readonly connectionState$ = this._connectionState.asObservable();
  public readonly dataSyncEvents$ = this._dataSyncEvents.asObservable();

  constructor(@Inject(AH_DATA_SYNC_SIGNALR_URL) baseSignalRUrl: string) {
    const hubUrl = `${baseSignalRUrl}/DataSyncEvents`;
    super(hubUrl);
    this.initialize();
  }

  public async initialize(): Promise<boolean> {
    try {
      await firstValueFrom(this.ensureHubConnection());
      this.registerEventHandlers();
      this._connectionState.next(signalR.HubConnectionState.Connected);

      this.hubConnection.onclose(() => {
        this._connectionState.next(signalR.HubConnectionState.Disconnected);
      });

      return true;
    } catch (error) {
      console.error('Failed to initialize hub connection:', error);
      this._connectionState.next(signalR.HubConnectionState.Disconnected);
      return false;
    }
  }

  protected registerEventHandlers(): void {
    this.hubConnection?.on('UpdateSyncEvent', this.handleDataSyncEvents);
  }

  private handleDataSyncEvents = (event: DataSyncEventDetailDto): void => {
    console.log('Received data sync event:', event);
    this._dataSyncEvents.next(event);
  };

  public reset(): void {
    this._dataSyncEvents.next(null);
  }
}
