import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ProcurementCodesCompanyDto } from '@ifhms/models/admin';

import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class ProcurementCodesCompaniesService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {}

  getProcurementCodesCompanies(): Observable<ProcurementCodesCompanyDto[]> {
    return this.http.get<ProcurementCodesCompanyDto[]>(
      `${this.apiUrl}/Reference/ProcurementCodes/OperationList`
    );
  }
}
