import { Injectable } from '@angular/core';
import { GroupPermissionsDto } from '@ifhms/models/admin';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GroupPermissionsActions } from './group-permissions.actions';

import * as reducer from './group-permissions.reducer';
import { PermissionsSelector } from './group-permissions.selectors';

@Injectable({ providedIn: 'root' })
export class GroupPermissionsFacade {

  state$: Observable<reducer.State> = this.store.select(PermissionsSelector.selectState);
  groupPermissions$: Observable<GroupPermissionsDto | null> = this.store.select(PermissionsSelector.selectGroupPermissions);
  loading: Observable<boolean> = this.store.select(PermissionsSelector.selectLoading);
  loaded: Observable<boolean> = this.store.select(PermissionsSelector.selectLoaded);

  constructor(private store: Store<reducer.State>) {
  }

  getGroupPermissions(forceReload = true, groupId?: string): void {
    this.store.dispatch(GroupPermissionsActions.getGroupPermissions({ forceReload, groupId }));
  }

  reset(): void {
    this.store.dispatch(GroupPermissionsActions.reset());
  }
}
