import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { TagColorsActions } from './tag-colors.actions';
import { TagColorsSelectors } from './tag-colors.selectors';
import * as reducer from './tag-colors.reducer';

@Injectable()
export class TagColorsFacade {

  loaded$ = this.store.select(TagColorsSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(TagColorsSelectors.selectTranslatedItems), this.loaded$);

  constructor(private store: Store<reducer.State>) { }

  get(): void {
    this.store.dispatch(TagColorsActions.get());
  }

}
