import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AH_USER_API_URL, UserProfileDto } from '@common/angular/core/models';

@Injectable()
export class CommonUserProfileApiService {
  constructor(
    private http: HttpClient,
    @Inject(AH_USER_API_URL) private apiUrl: string
  ) {}

  getAuthUserProfile(authUserId: string): Observable<UserProfileDto> {
    return this.http.get<UserProfileDto>(`${this.apiUrl}/UserProfile/${authUserId}/AuthProfile`);
  }

  updateUserLangSettings(userId: string, langCode: string): Observable<void> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.put<void>(`${this.apiUrl}/UserProfile/${userId}/Language`, JSON.stringify(langCode), { headers });
  }

}
