import { NavListBaseItem } from '@ifhms/common/angular/features/navigation';

export enum AdminNavigationMenu {
  Company,
  Enterprise,
  Operation,
  Group
}

export type CompanyMenuItemsFn = (companySlug: string) => NavListBaseItem[];
export type FeedlotMenuItemsFn = (
  companySlug: string,
  feedlotId: string,
  isDevelopmentMode?: boolean
) => NavListBaseItem[];
export type GroupMenuItemsFn = (groupSlug: string) => NavListBaseItem[];
