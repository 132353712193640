import { createReducer, on } from '@ngrx/store';

import { GroupDto } from '@ifhms/models/admin';

import { GroupActions } from './group.actions';

export const FEATURE_NAME = 'group';

export interface State {
  group: GroupDto | null;
  loading: boolean;
  loaded: boolean;
}

const initialState: State = {
  group: null,
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,

  on(GroupActions.get, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),

  on(GroupActions.getSuccess, (state, action) => ({
    ...state,
    group: action.group,
    loading: false,
    loaded: true
  })),

  on(GroupActions.getBySlugSuccess, (state, action) => {
    const updatedGroup = {
      ...state.group,
      ...action.group
    };
    return {
      ...state,
      group: action.updateCurrent ? updatedGroup : action.group,
      loading: false,
      loaded: true,
      updating: false,
      updated: false
    };
  }),

  on(GroupActions.getSuccessCached, (state) => ({
    ...state,
    loading: false,
    loaded: true
  })),

  on(GroupActions.error, GroupActions.reset, () => initialState)
)

export const getGroup = (state: State): GroupDto | null => state.group;
export const getGroupId = (state: State): string => state?.group?.id || '';
export const getGroupName = (state: State): string => state?.group?.name || '';
export const getGroupOperationIds = (state: State): string[] => state?.group?.operationIds || [];
export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
