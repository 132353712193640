export function getDataSyncEventActionLabel(
  action: DataSyncEventAction
): string {
  return DataSyncEventAction[action] as string;
}

export enum DataSyncEventAction {
  ShipmentWorkOrderCreate,
  ShipmentWorkOrderUpdate,
  ShipmentWorkOrderDelete,
  ShipmentEventDelete,
  RailCreateWorkOrder,
  RailUpdateWorkOrder,
  RailDeleteWorkOrder,
  RailScheduleWorkOrder,
  RailCreateEvent,
  RailUpdateEvent,
  RailDeleteEvent,
  LotCreate,
  LotUpdate,
  LotTransferWorkOrderCreate,
  LotTransferWorkOrderUpdate,
  LotTransferWorkOrderDelete,
  LotTransferEventDelete,
  PenCreate,
  PenUpdate,
  PostMortemCreate,
  PostMortemUpdate,
  PostMortemDelete,
  ArrivalWorkOrderCreate,
  ArrivalWorkOrderUpdate,
  ArrivalWorkOrderDelete,
  ArrivalEventCreate,
  ArrivalEventUpdate,
  ArrivalEventDelete,
  AcquisitionTypeCreate,
  AcquisitionTypeUpdate,
  VendorCreate,
  VendorUpdate,
  FacilityCreate,
  FacilityUpdate,
  RailDestinationCreate,
  RailDestinationUpdate,
  UnitTypeCreate,
  UnitTypeUpdate,
  TagLocationCreate,
  TagLocationUpdate,
  RouteCreate,
  RouteUpdate,
  PurchaserCreate,
  PurchaserUpdate,
  ProductCreate,
  ProductUpdate,
  ProcedureCreate,
  ProcedureUpdate,
  ProductTypeCreate,
  ProductTypeUpdate,
  MarketingPlanCreate,
  MarketingPlanUpdate,
  GenderCreate,
  GenderUpdate,
  FeedingPlanCreate,
  FeedingPlanUpdate,
  DoseUnitCreate,
  DoseUnitUpdate,
  DentitionCreate,
  DentitionUpdate,
  BreedTypeCreate,
  BreedTypeUpdate,
  BreedCreate,
  BreedUpdate,
  BreedClassCreate,
  BreedClassUpdate,
  PricingMethodCreate,
  PricingMethodUpdate,
  RegionCreate,
  RegionUpdate,
  IdTypeCreate,
  IdTypeUpdate,
  DxGroupCreate,
  DxGroupUpdate,
  DiagnosisCreate,
  DiagnosisUpdate,
  RiskCategoryCreate,
  RiskCategoryUpdate,
  TagColorCreate,
  TagColorUpdate,
  OtherFeesCreate,
  OtherFeesUpdate,
  PenTypeCreate,
  PenTypeUpdate,
  CoatColorCreate,
  CoatColorUpdate,
  CustomFieldUpdate,
  CustomFieldCreate,
  AlleyCreate,
  AlleyUpdate,
  AgeClassCreate,
  AgeClassUpdate,
  LotOwnerCreate,
  LotOwnerUpdate,
  LabourChargeCreate,
  LabourChargeUpdate,
  SourceCreate,
  SourceUpdate,
  UserCreate,
  UserUpdate,
  FeedlotSettingsUpdate,
  FeedlotWorkstationRevoked,
  FeedlotWorkstationEnabled,
  FeedlotWorkstationDisabled,
  TreatmentEventCreate,
  TreatmentEventUpdate,
  TreatmentEventDelete,
  RehandlingWorkOrderCreate,
  RehandlingWorkOrderUpdate,
  RehandlingWorkOrderDelete,
  RehandlingCreateEvent,
  RehandlingUpdateEvent,
  RehandlingDeleteEvent,
  MovementWorkOrderCreate,
  MovementWorkOrderUpdate,
  MovementWorkOrderDelete,
  MovementEventDelete,
  ProtocolsUpdate,
  ReferenceDataUpdate
}
