import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { filter, map, Observable, take, tap } from 'rxjs';

import { AuthFacade, AuthState } from '../+state';
import { AuthStorage, AUTH_CONFIG, AUTH_STORAGE, AuthServiceConfig, AuthErrorType } from '../interfaces';
import { AuthErrorHandlingService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class AuthBaseRouteRedirectGuard implements CanActivate {

  constructor(
    private readonly authFacade: AuthFacade,
    private readonly router: Router,
    private readonly authErrorHandlingService: AuthErrorHandlingService,
    @Inject(AUTH_STORAGE) private readonly authStorage: AuthStorage,
    @Inject(AUTH_CONFIG) private readonly authConfig: AuthServiceConfig
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<UrlTree> | UrlTree {
    const isLoginCancelled = route.queryParams['error'] === AuthErrorType.AUTH_CANCELLED;
    if (!this.authConfig.forceUserLogin && isLoginCancelled) {
      // User has cancelled auth, redirect to home page
      return this.router.parseUrl('/');
    }
    return this.authFacade.state$.pipe(
      filter(({ isAuthInProgress }) => !isAuthInProgress),
      tap((authState: AuthState) => {
        if (!authState.isAuthComplete) {
          this.authFacade.login();
        }
      }),
      filter(({ isAuthComplete }) => isAuthComplete),
      map((authState) => {
        const returnUrlKey = this.authConfig.storageReturnUrlKey;
        const redirectSuccessUrl = this.authStorage.getItem(returnUrlKey);
        const errNamespace = 'auth-redirect';

        if (authState.err || !authState.isAuthenticated) {
          this.authErrorHandlingService.handleAuthError(errNamespace);
        }
        this.authErrorHandlingService.clearAuthErrorState(errNamespace);
        if (redirectSuccessUrl) {
          this.authStorage.removeItem(returnUrlKey);
          return this.router.parseUrl(redirectSuccessUrl);
        }
        return this.router.parseUrl('/');
      }),
      take(1)
    );
  }
}