import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import * as CompanyPermissions from './company/company-permissions.reducer';
import * as EnterprisePermissions from './enterprise/enterprise-permissions.reducer';
import * as OperationPermissions from './operation/operation-permissions.reducer';
import * as GroupPermissions from './group/group-permissions.reducer';

export const FEATURE_NAME = 'permissions';

export const selectFeatureState = createFeatureSelector<State>(FEATURE_NAME);

export const reducer: ActionReducerMap<State> = {
  enterprisePermissions: EnterprisePermissions.reducer,
  companyPermissions: CompanyPermissions.reducer,
  operationPermissions: OperationPermissions.reducer,
  groupPermissions: GroupPermissions.reducer
};

export interface State {
  enterprisePermissions: EnterprisePermissions.State;
  companyPermissions: CompanyPermissions.State;
  operationPermissions: OperationPermissions.State;
  groupPermissions: GroupPermissions.State;
}
