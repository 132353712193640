import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { getTranslationScope } from '@common/angular/translation';
import { ProviderScope, TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { AbstractFormFieldConfigComponent } from '@sersi/angular/formly/core';

import { PermissionFieldConfigService } from '../../services';

@Component({
  selector: 'aa-admin-module-access-form-group',
  templateUrl: './module-access-form-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PermissionFieldConfigService]
})
export class ModuleAccessFormGroupComponent extends AbstractFormFieldConfigComponent {

  private fieldService = inject(PermissionFieldConfigService);
  private providerScope = inject<ProviderScope>(TRANSLOCO_SCOPE);

  protected override scopedNamespace = `${getTranslationScope(this.providerScope)}.components.module-access`;

  constructor() {
    super();
    this.fieldService.scopedNamespace += '.module-access';
  }

  protected getFieldGroupConfig(): FormlyFieldConfig[] {
    return [
      this.setAccessSection()
    ];
  }

  private setAccessSection(): FormlyFieldConfig {
    return {
      fieldGroupClassName: 'dual-col mb-5',
      fieldGroup: [
        this.fieldService.getAccessPermissionFieldConfig('hasFrm'),
        this.fieldService.getAccessPermissionFieldConfig('hasProcurement'),
        this.fieldService.getAccessPermissionFieldConfig('hasOsvrHealthVisit'),
        this.fieldService.getAccessPermissionFieldConfig('hasOsvrNutritionVisit'),
        this.fieldService.getAccessPermissionFieldConfig('hasChat'),
        this.fieldService.getAccessPermissionFieldConfig('hasPostMortem')
      ]
    }
  }

}
