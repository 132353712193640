import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { GroupListItemDto } from '@ifhms/models/admin';

import { GroupsActions } from './groups.actions';
import { State } from './groups.reducer';
import { GroupsSelectors } from './groups.selectors';
import { getLoadedStateData } from '@common/angular/utils';

@Injectable()
export class GroupsFacade {

  state$: Observable<State> = this.store.select(GroupsSelectors.selectState);
  userGroups$: Observable<GroupListItemDto[]> = this.store.select(GroupsSelectors.selectAllUserGroups);

  isLoading$: Observable<boolean> = this.store.select(GroupsSelectors.selectLoading);
  isLoaded$: Observable<boolean> = this.store.select(GroupsSelectors.selectLoaded);

  loadedUserGroups$: Observable<GroupListItemDto[]> = getLoadedStateData<GroupListItemDto[]>(this.userGroups$, this.isLoaded$);

  constructor(private store: Store<State>) { }

  getAllUserGroups(forceReload = true): void {
    this.store.dispatch(GroupsActions.getAllUserGroups({ forceReload }));
  }

  reset(): void {
    this.store.dispatch(GroupsActions.reset());
  }

}
