import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as GroupReducer from './group.reducer';
import { GroupDto } from '@ifhms/models/admin';

const selectFeatureState = createFeatureSelector<GroupReducer.State>(GroupReducer.FEATURE_NAME);

const selectState = createSelector(
  selectFeatureState,
  (state: GroupReducer.State) => state
);

const selectGroup = createSelector(selectState, GroupReducer.getGroup);
const selectGroupId = createSelector(selectState, GroupReducer.getGroupId);
const selectGroupSlug = createSelector(
  selectGroup,
  (group: GroupDto | null) => {
    if (group) {
      return group.slug;
    }
    else {
      return '';
    }
  }
);

const selectGroupName = createSelector(selectState, GroupReducer.getGroupName);
const selectGroupOperationIds = createSelector(selectState, GroupReducer.getGroupOperationIds);
const selectLoading = createSelector(selectState, GroupReducer.getLoading);
const selectLoaded = createSelector(selectState, GroupReducer.getLoaded);

export const GroupSelectors = {
  selectGroupId,
  selectGroupSlug,
  selectGroupName,
  selectGroupOperationIds,
  selectState,
  selectGroup,
  selectLoaded,
  selectLoading
};
