import { createAction, props } from '@ngrx/store';

import { GroupListItemDto } from '@ifhms/models/admin';

const NAMESPACE = '[GROUPS]';

const getAllUserGroups = createAction(
  `${NAMESPACE} Get All User Groups`,
  props<{ forceReload: boolean }>()
);

const getAllUserGroupsSuccess = createAction(
  `${NAMESPACE} Get All User Groups Success`,
  props<{ groups: GroupListItemDto[] }>()
);

const getAllUserGroupsSuccessCached = createAction(
  `${NAMESPACE} Get All User Groups Success Cached`
);

const error = createAction(`${NAMESPACE} Error`);

const reset = createAction(`${NAMESPACE} Reset`);

export const GroupsActions = {
  getAllUserGroups,
  getAllUserGroupsSuccess,
  getAllUserGroupsSuccessCached,
  error,
  reset
};
