import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SelectListItemWithTranslationDto, UserProfileDto } from '@common/angular/core/models';

import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class ProcurementCodesSelectListService {
  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string
  ) {}

  getAuthUserProfile(authUserId: string): Observable<UserProfileDto> {
    return this.http.get<UserProfileDto>(
      `${this.apiUrl}/UserProfile/${authUserId}/AuthProfile`
    );
  }

  getTypes(): Observable<SelectListItemWithTranslationDto[]> {
    return this.http.get<SelectListItemWithTranslationDto[]>(
      `${this.apiUrl}/Reference/ProcurementCodes/TypeSelectList`
    );
  }

  getCategories(): Observable<SelectListItemWithTranslationDto[]> {
    return this.http.get<SelectListItemWithTranslationDto[]>(
      `${this.apiUrl}/Reference/ProcurementCodes/CategorySelectList`
    );
  }
}
