import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BannerType } from '@common/angular/interfaces';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'sersi-banner-type',
  templateUrl: './banner-type.component.html',
  styleUrls: ['./banner-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BannerTypeComponent extends FieldType<FieldTypeConfig> {

  override defaultOptions = {
    props: {
      inline: false,
      severity: BannerType.INFO,
      title: 'Info',
      message: 'I am example text',
      closable: false
    }
  }

}
