import { GroupPermissionsDto } from '@ifhms/models/admin';
import { createReducer, on } from '@ngrx/store';
import { GroupPermissionsActions } from './group-permissions.actions';

export interface State {
  groupPermissions: GroupPermissionsDto | null;
  groupId: string | null;
  loading: boolean;
  loaded: boolean;
}

export const initialState: State = {
  groupPermissions: null,
  groupId: null,
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,

  on(GroupPermissionsActions.getGroupPermissions, (state, action) => {
    return {
      ...state,
      loading: true,
      loaded: false,
      groupId: action.groupId || null
    }
  }),

  on(GroupPermissionsActions.getGroupPermissionsSuccess, (state, action) => {
    return {
      ...state,
      groupPermissions: action.groupPermissions,
      groupId: action.groupId,
      loading: false,
      loaded: true
    }
  }),

  on(GroupPermissionsActions.reset, () => initialState)
);

export const getGroupPermissions = (state: State): GroupPermissionsDto | null => state.groupPermissions;
export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
