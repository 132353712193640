export enum WorkOrderType {
  Arrival = 1,
  LotTransfer = 2,
  Movement = 3,
  Rail = 4,
  ReHandling = 5,
  Shipment = 6,
  TransferOut = 7,
  TransferIn = 8
}
