import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { GroupDto } from '@ifhms/models/admin';

import { GroupActions } from './group.actions';
import { State } from './group.reducer';
import { GroupSelectors } from './group.selectors';

@Injectable()
export class GroupFacade {
  state$: Observable<State> = this.store.select(GroupSelectors.selectState);
  group$: Observable<GroupDto | null> = this.store.select(GroupSelectors.selectGroup);
  groupId$: Observable<string> = this.store.select(GroupSelectors.selectGroupId);
  groupName$: Observable<string> = this.store.select(GroupSelectors.selectGroupName);
  groupSlug$: Observable<string> = this.store.select(
    GroupSelectors.selectGroupSlug
  );
  groupOperationIds$: Observable<string[]> = this.store.select(GroupSelectors.selectGroupOperationIds);

  isLoading$: Observable<boolean> = this.store.select(GroupSelectors.selectLoading);
  isLoaded$: Observable<boolean> = this.store.select(GroupSelectors.selectLoaded);

  constructor(private store: Store<State>) { }

  get(id: string, forceReload = true): void {
    this.store.dispatch(GroupActions.get({ id, forceReload }));
  }

  getBySlug(slug: string, updateCurrent = false): void {
    this.store.dispatch(GroupActions.getBySlug({ slug, updateCurrent }));
  }

  reset(): void {
    this.store.dispatch(GroupActions.reset());
  }

}
