import { ChangeDetectionStrategy, Component, Inject, inject } from '@angular/core';
import { getTranslationScope } from '@common/angular/translation';
import { ProviderScope, TRANSLOCO_SCOPE } from '@jsverse/transloco';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SHOW_DEV_FEATURE } from '@common/angular/config';

import { AbstractFormFieldConfigComponent, FormlyWrappersEnum } from '@sersi/angular/formly/core';

import { PermissionFieldConfigService } from '../../services';

@Component({
  selector: 'aa-admin-frm-permission-form-group',
  templateUrl: './frm-permission-form-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PermissionFieldConfigService]
})
export class FrmPermissionFormGroupComponent extends AbstractFormFieldConfigComponent {

  private fieldService = inject(PermissionFieldConfigService);
  private providerScope = inject<ProviderScope>(TRANSLOCO_SCOPE);

  protected override scopedNamespace = `${getTranslationScope(this.providerScope)}.components.frm-permission`;

  constructor(@Inject(SHOW_DEV_FEATURE) private showDevFeature: boolean) {
    super();
    this.fieldService.scopedNamespace += '.frm-permission';
  }

  protected getFieldGroupConfig(): FormlyFieldConfig[] {
    return [
      this.setAnimalSection(),
      this.setLotsSection(),
      this.setPensSection(),
      this.setProductManagementSection(),
      this.setArrivalSection(),
      this.setReHandlingSection(),
      this.setTreatmentSection(),
      this.setMovementSection(),
      this.setLotTransferSection(),
      this.setRailSection(),
      this.setShipmentSection(),
      this.showDevFeature ? this.setTransferOutSection() : {} as FormlyFieldConfig,
      // this.showDevFeature ? this.setTransferInSection() : {} as FormlyFieldConfig,
      this.setPostMortemSection(),
      this.setFeedlotSettingsSection(),
      this.setReportsSection()
    ];
  }

  private setAnimalSection(): FormlyFieldConfig {
    return {
      wrappers: [FormlyWrappersEnum.SECTION],
      fieldGroupClassName: 'dual-col mb-5',
      props: {
        label$: this.getTranslation$('animals'),
        subHeading: true
      },
      fieldGroup: [
        this.fieldService.getReadPermissionFieldConfig('canViewAnimals', ['canEditAnimals']),
        this.fieldService.getWritePermissionFieldConfig('canEditAnimals', ['canViewAnimals'])
      ]
    }
  }

  private setLotsSection(): FormlyFieldConfig {
    return {
      wrappers: [FormlyWrappersEnum.SECTION],
      fieldGroupClassName: 'dual-col mb-5',
      props: {
        label$: this.getTranslation$('lots'),
        subHeading: true
      },
      fieldGroup: [
        this.fieldService.getReadPermissionFieldConfig('canViewLots', ['canEditLots']),
        this.fieldService.getWritePermissionFieldConfig('canEditLots', ['canViewLots']),
        this.fieldService.getReadPermissionFieldConfig('canViewLotBilling', ['canEditLotBilling']),
        this.fieldService.getWritePermissionFieldConfig('canEditLotBilling', ['canViewLotBilling'])
      ]
    }
  }

  private setPensSection(): FormlyFieldConfig {
    return {
      wrappers: [FormlyWrappersEnum.SECTION],
      fieldGroupClassName: 'dual-col mb-5',
      props: {
        label$: this.getTranslation$('pens'),
        subHeading: true
      },
      fieldGroup: [
        this.fieldService.getReadPermissionFieldConfig('canViewPens', ['canEditPens']),
        this.fieldService.getWritePermissionFieldConfig('canEditPens', ['canViewPens'])
      ]
    }
  }

  private setProductManagementSection(): FormlyFieldConfig {
    return {
      wrappers: [FormlyWrappersEnum.SECTION],
      fieldGroupClassName: 'dual-col mb-5',
      props: {
        label$: this.getTranslation$('product-management'),
        subHeading: true
      },
      fieldGroup: [
        this.fieldService.getReadPermissionFieldConfig('canViewProductDefinition', ['canEditProductDefinition']),
        this.fieldService.getWritePermissionFieldConfig('canEditProductDefinition', ['canViewProductDefinition']),
        this.fieldService.getReadPermissionFieldConfig('canViewProductMapping', ['canEditProductMapping']),
        this.fieldService.getWritePermissionFieldConfig('canEditProductMapping', ['canViewProductMapping']),
        this.fieldService.getReadPermissionFieldConfig('canViewProductPricing', ['canEditProductPricing']),
        this.fieldService.getWritePermissionFieldConfig('canEditProductPricing', ['canViewProductPricing']),
        this.fieldService.getReadPermissionFieldConfig('canViewProductReceipt', ['canEditProductReceipt']),
        this.fieldService.getWritePermissionFieldConfig('canEditProductReceipt', ['canViewProductReceipt']),
        this.fieldService.getReadPermissionFieldConfig('canViewProductReconciliation', ['canEditProductReconciliation']),
        this.fieldService.getWritePermissionFieldConfig('canEditProductReconciliation', ['canViewProductReconciliation']),
        this.fieldService.getAccessPermissionFieldConfig('canViewProductLotNoExpiry'),
        this.fieldService.getAccessPermissionFieldConfig('canViewProductTransactions')
      ]
    }
  }

  private setArrivalSection(): FormlyFieldConfig {
    return {
      wrappers: [FormlyWrappersEnum.SECTION],
      fieldGroupClassName: 'dual-col mb-5',
      props: {
        label$: this.getTranslation$('arrival'),
        subHeading: true
      },
      fieldGroup: [
        this.fieldService.getReadPermissionFieldConfig('canViewArrivalWorkOrders', [
          'canEditArrivalWorkOrders',
          'canFinalizeArrivalWorkOrders'
        ]),
        this.fieldService.getWritePermissionFieldConfig('canEditArrivalWorkOrders', ['canViewArrivalWorkOrders']),
        this.fieldService.getWritePermissionFieldConfig('canFinalizeArrivalWorkOrders', ['canViewArrivalWorkOrders']),
        this.fieldService.getReadPermissionFieldConfig('canViewArrivalEvents', ['canEditArrivalEvents']),
        this.fieldService.getWritePermissionFieldConfig('canEditArrivalEvents', ['canViewArrivalEvents'])
      ]
    }
  }

  private setReHandlingSection(): FormlyFieldConfig {
    return {
      wrappers: [FormlyWrappersEnum.SECTION],
      fieldGroupClassName: 'dual-col mb-5',
      props: {
        label$: this.getTranslation$('re-handling'),
        subHeading: true
      },
      fieldGroup: [
        this.fieldService.getReadPermissionFieldConfig('canViewRehandlingWorkOrders', [
          'canEditRehandlingWorkOrders',
          'canFinalizeRehandlingWorkOrders'
        ]),
        this.fieldService.getWritePermissionFieldConfig('canEditRehandlingWorkOrders', ['canViewRehandlingWorkOrders']),
        this.fieldService.getWritePermissionFieldConfig('canFinalizeRehandlingWorkOrders', ['canViewRehandlingWorkOrders']),
        this.fieldService.getReadPermissionFieldConfig('canViewRehandlingEvents', ['canEditRehandlingEvents']),
        this.fieldService.getWritePermissionFieldConfig('canEditRehandlingEvents', ['canViewRehandlingEvents'])
      ]
    }
  }

  private setTreatmentSection(): FormlyFieldConfig {
    return {
      wrappers: [FormlyWrappersEnum.SECTION],
      fieldGroupClassName: 'dual-col mb-5',
      props: {
        label$: this.getTranslation$('treatment'),
        subHeading: true
      },
      fieldGroup: [
        this.fieldService.getReadPermissionFieldConfig('canViewTreatmentEvents', [
          'canEditTreatmentEvents',
          'canCompleteTreatmentEvents'
        ]),
        this.fieldService.getWritePermissionFieldConfig('canEditTreatmentEvents', ['canViewTreatmentEvents']),
        this.fieldService.getWritePermissionFieldConfig('canCompleteTreatmentEvents', ['canViewTreatmentEvents'])
      ]
    }
  }

  private setMovementSection(): FormlyFieldConfig {
    return {
      wrappers: [FormlyWrappersEnum.SECTION],
      fieldGroupClassName: 'dual-col mb-5',
      props: {
        label$: this.getTranslation$('movement'),
        subHeading: true
      },
      fieldGroup: [
        this.fieldService.getReadPermissionFieldConfig('canViewMovementWorkOrders', [
          'canEditMovementWorkOrders',
          'canFinalizeMovementWorkOrders'
        ]),
        this.fieldService.getWritePermissionFieldConfig('canEditMovementWorkOrders', ['canViewMovementWorkOrders']),
        this.fieldService.getWritePermissionFieldConfig('canFinalizeMovementWorkOrders', ['canViewMovementWorkOrders'])
      ]
    }
  }

  private setLotTransferSection(): FormlyFieldConfig {
    return {
      wrappers: [FormlyWrappersEnum.SECTION],
      fieldGroupClassName: 'dual-col mb-5',
      props: {
        label$: this.getTranslation$('lot-transfer'),
        subHeading: true
      },
      fieldGroup: [
        this.fieldService.getReadPermissionFieldConfig('canViewWorkOrdersLotTransfers', [
          'canEditWorkOrdersLotTransfers',
          'canFinalizeWorkOrdersLotTransfers'
        ]),
        this.fieldService.getWritePermissionFieldConfig('canEditWorkOrdersLotTransfers', ['canViewWorkOrdersLotTransfers']),
        this.fieldService.getWritePermissionFieldConfig('canFinalizeWorkOrdersLotTransfers', ['canViewWorkOrdersLotTransfers'])
      ]
    }
  }

  private setRailSection(): FormlyFieldConfig {
    return {
      wrappers: [FormlyWrappersEnum.SECTION],
      fieldGroupClassName: 'dual-col mb-5',
      props: {
        label$: this.getTranslation$('rail'),
        subHeading: true
      },
      fieldGroup: [
        this.fieldService.getReadPermissionFieldConfig('canViewRailWorkOrders', [
          'canEditRailWorkOrders',
          'canFinalizeRailWorkOrders'
        ]),
        this.fieldService.getWritePermissionFieldConfig('canEditRailWorkOrders', ['canViewRailWorkOrders']),
        this.fieldService.getWritePermissionFieldConfig('canFinalizeRailWorkOrders', ['canViewRailWorkOrders']),
        this.fieldService.getReadPermissionFieldConfig('canViewRailEvents', ['canEditRailEvents']),
        this.fieldService.getWritePermissionFieldConfig('canEditRailEvents', ['canViewRailEvents'])
      ]
    }
  }

  private setShipmentSection(): FormlyFieldConfig {
    return {
      wrappers: [FormlyWrappersEnum.SECTION],
      fieldGroupClassName: 'dual-col mb-5',
      props: {
        label$: this.getTranslation$('shipment'),
        subHeading: true
      },
      fieldGroup: [
        this.fieldService.getReadPermissionFieldConfig('canViewShipmentWorkOrders', [
          'canEditShipmentWorkOrders',
          'canFinalizeShipmentWorkOrders'
        ]),
        this.fieldService.getWritePermissionFieldConfig('canEditShipmentWorkOrders', ['canViewShipmentWorkOrders']),
        this.fieldService.getWritePermissionFieldConfig('canFinalizeShipmentWorkOrders', ['canViewShipmentWorkOrders'])
      ]
    }
  }

  private setTransferOutSection(): FormlyFieldConfig {
    return {
      wrappers: [FormlyWrappersEnum.SECTION],
      fieldGroupClassName: 'dual-col mb-5',
      props: {
        label$: this.getTranslation$('transfer-out'),
        subHeading: true
      },
      fieldGroup: [
        this.fieldService.getReadPermissionFieldConfig('canViewTransferOutWorkOrders', [
          'canEditTransferOutWorkOrders',
          'canFinalizeTransferOutWorkOrders'
        ]),
        this.fieldService.getWritePermissionFieldConfig('canEditTransferOutWorkOrders', ['canViewTransferOutWorkOrders']),
        this.fieldService.getWritePermissionFieldConfig('canFinalizeTransferOutWorkOrders', ['canViewTransferOutWorkOrders'])
      ]
    }
  }

  // TODO: add transfer in section
  // private setTransferInSection(): FormlyFieldConfig {
  //   return {
  //     wrappers: [FormlyWrappersEnum.SECTION],
  //     fieldGroupClassName: 'dual-col mb-5',
  //     props: {
  //       label$: this.getTranslation$('transfer-out'),
  //       subHeading: true
  //     },
  //     fieldGroup: [
  //       this.fieldService.getReadPermissionFieldConfig('canViewTransferInWorkOrders', [
  //         'canEditTransferInWorkOrders',
  //         'canFinalizeTransferInWorkOrders'
  //       ]),
  //       this.fieldService.getWritePermissionFieldConfig('canEditTransferInWorkOrders', ['canViewTransferInWorkOrders']),
  //       this.fieldService.getWritePermissionFieldConfig('canFinalizeTransferInWorkOrders', ['canViewTransferInWorkOrders'])
  //     ]
  //   }
  // }

  private setPostMortemSection(): FormlyFieldConfig {
    return {
      wrappers: [FormlyWrappersEnum.SECTION],
      fieldGroupClassName: 'dual-col mb-5',
      props: {
        label$: this.getTranslation$('post-mortem'),
        subHeading: true
      },
      fieldGroup: [
        this.fieldService.getReadPermissionFieldConfig('canViewPostMortemEvents', [
          'canEditPostMortemEvents',
          'canCompletePostMortemEvents'
        ]),
        this.fieldService.getWritePermissionFieldConfig('canEditPostMortemEvents', ['canViewPostMortemEvents']),
        this.fieldService.getWritePermissionFieldConfig('canCompletePostMortemEvents', ['canViewPostMortemEvents'])
      ]
    }
  }

  private setFeedlotSettingsSection(): FormlyFieldConfig {
    return {
      wrappers: [FormlyWrappersEnum.SECTION],
      fieldGroupClassName: 'dual-col mb-5',
      props: {
        label$: this.getTranslation$('feedlot-settings'),
        subHeading: true
      },
      fieldGroup: [
        this.fieldService.getReadPermissionFieldConfig('canViewPricingSettings', ['canEditPricingSettings']),
        this.fieldService.getWritePermissionFieldConfig('canEditPricingSettings', ['canViewPricingSettings']),
        this.fieldService.getReadPermissionFieldConfig('canViewDefaultValueSettings', ['canEditDefaultValueSettings']),
        this.fieldService.getWritePermissionFieldConfig('canEditDefaultValueSettings', ['canViewDefaultValueSettings']),
        this.fieldService.getReadPermissionFieldConfig('canViewTargetWeightSettings', ['canEditTargetWeightSettings']),
        this.fieldService.getWritePermissionFieldConfig('canEditTargetWeightSettings', ['canViewTargetWeightSettings']),
        this.fieldService.getReadPermissionFieldConfig('canViewArrivalSettings', ['canEditArrivalSettings']),
        this.fieldService.getWritePermissionFieldConfig('canEditArrivalSettings', ['canViewArrivalSettings']),
        this.fieldService.getReadPermissionFieldConfig('canViewRehandlingSettings', ['canEditRehandlingSettings']),
        this.fieldService.getWritePermissionFieldConfig('canEditRehandlingSettings', ['canViewRehandlingSettings']),
        this.fieldService.getReadPermissionFieldConfig('canViewPostMortemSettings', ['canEditPostMortemSettings']),
        this.fieldService.getWritePermissionFieldConfig('canEditPostMortemSettings', ['canViewPostMortemSettings']),
        this.fieldService.getReadPermissionFieldConfig('canViewAdgSettings', ['canEditAdgSettings']),
        this.fieldService.getWritePermissionFieldConfig('canEditAdgSettings', ['canViewAdgSettings']),
        this.fieldService.getReadPermissionFieldConfig('canViewZeroScaleReminderSettings', ['canEditZeroScaleReminderSettings']),
        this.fieldService.getWritePermissionFieldConfig('canEditZeroScaleReminderSettings', ['canViewZeroScaleReminderSettings']),
        this.fieldService.getReadPermissionFieldConfig('canViewLabourCharges', ['canEditLabourCharges']),
        this.fieldService.getWritePermissionFieldConfig('canEditLabourCharges', ['canViewLabourCharges']),
        this.fieldService.getReadPermissionFieldConfig('canViewProcedureCharges', ['canEditProcedureCharges']),
        this.fieldService.getWritePermissionFieldConfig('canEditProcedureCharges', ['canViewProcedureCharges']),
        this.fieldService.getReadPermissionFieldConfig('canViewPenCharges', ['canEditPenCharges']),
        this.fieldService.getWritePermissionFieldConfig('canEditPenCharges', ['canViewPenCharges']),
        this.fieldService.getReadPermissionFieldConfig('canViewOtherFees', ['canEditOtherFees']),
        this.fieldService.getWritePermissionFieldConfig('canEditOtherFees', ['canViewOtherFees']),
        this.fieldService.getReadPermissionFieldConfig('canViewCustomFields', ['canEditCustomFields']),
        this.fieldService.getWritePermissionFieldConfig('canEditCustomFields', ['canViewCustomFields']),
        this.fieldService.getReadPermissionFieldConfig('canViewTagSettings', ['canEditTagSettings']),
        this.fieldService.getWritePermissionFieldConfig('canEditTagSettings', ['canViewTagSettings']),
        this.fieldService.getReadPermissionFieldConfig('canViewSortGroups', ['canEditSortGroups']),
        this.fieldService.getWritePermissionFieldConfig('canEditSortGroups', ['canViewSortGroups']),
        this.fieldService.getReadPermissionFieldConfig('canViewSortGroupTemplates', ['canEditSortGroupTemplates']),
        this.fieldService.getWritePermissionFieldConfig('canEditSortGroupTemplates', ['canViewSortGroupTemplates']),
        this.fieldService.getReadPermissionFieldConfig('canViewCciaSettings', ['canEditCciaSettings']),
        this.fieldService.getWritePermissionFieldConfig('canEditCciaSettings', ['canViewCciaSettings']),
        this.fieldService.getReadPermissionFieldConfig('canViewElectronicTags', ['canEditElectronicTags']),
        this.fieldService.getWritePermissionFieldConfig('canEditElectronicTags', ['canViewElectronicTags'])
      ]
    }
  }

  private setReportsSection(): FormlyFieldConfig {
    return {
      wrappers: [FormlyWrappersEnum.SECTION],
      fieldGroupClassName: 'dual-col mb-5',
      props: {
        label$: this.getTranslation$('reports'),
        subHeading: true
      },
      fieldGroup: [
        this.fieldService.getAccessPermissionFieldConfig('canViewAnimalTreatmentHistoryReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewArrivalWorkOrderReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewCattleMovementReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewCurrentProductPricingReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewDailyTreatmentHistoryByFacilityReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewEventDetailsReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewHospitalOccupancyReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewLotExpensesSummaryReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewLotHealthSummaryReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewLotInventoryReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewLotOtherDiseasesDetailReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewLotTransferWorkOrderReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewLotUfDetailReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewMovementWorkOrderReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewOtherDiseasesManagementReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewPenInventoryReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewPhysicalInventoryWorksheetReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewPostMortemDetailByDateReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewPostMortemDetailReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewProductOnHandReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewProductUsageReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewRailDetailReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewRailWorkOrderReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewReconciledPhysicalInventoryValueReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewRehandlingWorkOrderReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewShipmentWorkOrderReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewTagAvailabilityReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewUfManagementReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewWithdrawalReport'),
        this.fieldService.getAccessPermissionFieldConfig('canViewTransferOutWorkOrderReport')
      ]
    }
  }
}
