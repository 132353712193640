import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as GroupReducer from './groups.reducer';

const selectFeatureState = createFeatureSelector<GroupReducer.State>(GroupReducer.FEATURE_NAME);

const selectState = createSelector(
  selectFeatureState,
  (state: GroupReducer.State) => state
);

const selectAllUserGroups = createSelector(selectState, GroupReducer.getAllUserGroups);
const selectLoading = createSelector(selectState, GroupReducer.getLoading);
const selectLoaded = createSelector(selectState, GroupReducer.getLoaded);

export const GroupsSelectors = {
  selectState,
  selectAllUserGroups,
  selectLoaded,
  selectLoading
};
