import { APP_CONFIG_BASE } from './app-config-base';
import { AppConfig } from '@ifhms/admin/web/core/config';

export const APP_CONFIG_FMS_STAGING: AppConfig = {
  ...APP_CONFIG_BASE,

  environmentName: 'staging',
  production: false,
  apiUrl: 'https://admin-api.staging.fms.feedlothealth.com/api',
  adminApiUrl: 'https://admin-api.staging.fms.feedlothealth.com/api',
  signalRUrl:
    'https://admin-api.staging.fms.feedlothealth.com/PushNotification',
  showDevFeature: false,
  authServerUrl: 'https://identity.staging.fms.feedlothealth.com',
  feedlotAppUrl: 'https://feedlot.staging.fms.feedlothealth.com',
  dataSyncSignalRUrl:
    'https://data-sync-api.staging.fms.feedlothealth.com/SignalRHub',
  dataSyncApiUrl: 'https://data-sync-api.staging.fms.feedlothealth.com/api',
  integrations: {
    heapAppId: '1637901110'
  },
  chatAiUrl: 'https://chat.staging.fms.feedlothealth.com/'
};
