import { UserProfileDto } from '@common/angular/core/models';
import { createReducer, on } from '@ngrx/store';
import { CommonUserProfileActions } from './common-user-profile.actions';

export const FEATURE_NAME = 'user-profile';

export interface State {
  userProfile: UserProfileDto | null;
  loading: boolean;
  loaded: boolean;
}

export const initialState: State = {
  userProfile: null,
  loading: false,
  loaded: false
};

export const reducer = createReducer(
  initialState,

  on(CommonUserProfileActions.get, state => ({
    ...state,
    loading: true,
    loaded: false,
    updating: false,
    updated: false
  })),

  on(CommonUserProfileActions.getSuccess, (state, action) => ({
    ...state,
    userProfile: action.userProfile,
    loading: false,
    loaded: true,
    updating: false,
    updated: false
  })),

  on(CommonUserProfileActions.updateLangSettings, (state) => ({
    ...state,
    updating: true
  })),

  on(CommonUserProfileActions.updateLangSettingsSuccess, (state, action) => ({
    ...state,
    userProfile: {
      ...state.userProfile!,
      language: action.langCode
    },
    loading: false,
    loaded: true,
    updating: false,
    updated: true
  })),

  on(CommonUserProfileActions.reset, () => ({
    ...initialState
  }))

);

export const getUserProfile = (state: State): UserProfileDto | null => state.userProfile;
export const getLoading = (state: State): boolean => state.loading;
export const getLoaded = (state: State): boolean => state.loaded;
export const getUserId = (profile: UserProfileDto | null): string | null => profile?.id || null;