export function camelCaseToKebab(camelCaseStr: string): string {
  // If the string is all uppercase, simply convert to lowercase and return
  if (camelCaseStr === camelCaseStr.toUpperCase()) {
    return camelCaseStr.toLowerCase();
  }
  // Convert the string
  // Remove leading hyphen if it exists
  // Return the new string
  return camelCaseStr
    .replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2') // Insert hyphen before uppercase letters
    .toLowerCase() // Convert to lowercase
    .replace(/--/g, '-') // Replace double hyphens with a single hyphen
    .replace(/^-/, '');
}

// Some Test String => some-test-string
export function transformTitleCaseToKebab(titleCaseStr: string): string {
  return titleCaseStr
    .trim() // Remove any leading or trailing whitespace
    .toLowerCase() // Convert to lowercase
    .replace(/\s+/g, '-'); // Replace all spaces with hyphens
}

export function pascalCaseToKebab(pascalCaseStr: string): string {
  return pascalCaseStr
    .replace(/([a-z0-9])([A-Z])/g, '$1-$2') // Insert hyphen between lowercase and uppercase letters
    .replace(/([A-Z]{2,})([A-Z][a-z])/g, '$1-$2') // Handle multiple consecutive uppercase letters (e.g. HTTPResponse -> http-response)
    .toLowerCase(); // Convert everything to lowercase
}

export function capitalizeFirstLetter(inputString: string): string {
  if (inputString.length === 0) {
    return inputString; // Return an empty string if the input is empty
  }

  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

export function isSearchTermInStrings(
  searchStr: string,
  ...targetStr: string[]
): boolean {
  return targetStr.some((item) => {
    return item.toLocaleLowerCase().includes(searchStr.toLocaleLowerCase());
  });
}

export function isStringEqual(a: string | null, b: string | null): boolean {
  return getSanitizedLowerCaseString(a) === getSanitizedLowerCaseString(b);
}

export function getSanitizedLowerCaseString(input: string | null): string {
  return input?.trim()?.toLowerCase() || '';
}

export function toUpperCaseFn(input: string | null): string | null {
  return input ? input.toUpperCase() : input;
}

export function addSpacesBetweenUpperCaseWords(input: string): string {
  return input.replace(/([a-z])([A-Z])/g, '$1 $2');
}
