export enum DataSyncEventType {
  Lot = 1,
  Shipment = 2,
  PostMortem = 3,
  Treatment = 4,
  Pen = 5,
  Arrival = 6,
  RefData = 7,
  User = 8,
  Feedlot = 9,
  FeedlotWorkstationManagement = 10,
  LotTransfer = 11,
  Movement = 12,
  Rail = 13,
  Rehandling = 14,
  Protocols = 15
}

export const DataSyncEventTypeLabels: Record<DataSyncEventType, string> = {
  [DataSyncEventType.Lot]: 'Lot',
  [DataSyncEventType.Shipment]: 'Shipment',
  [DataSyncEventType.PostMortem]: 'Post Mortem',
  [DataSyncEventType.Treatment]: 'Treatment',
  [DataSyncEventType.Pen]: 'Pen',
  [DataSyncEventType.Arrival]: 'Arrival',
  [DataSyncEventType.RefData]: 'Reference Data',
  [DataSyncEventType.User]: 'User',
  [DataSyncEventType.Feedlot]: 'Feedlot',
  [DataSyncEventType.FeedlotWorkstationManagement]: 'Feedlot Workstation Management',
  [DataSyncEventType.LotTransfer]: 'Lot Transfer',
  [DataSyncEventType.Movement]: 'Movement',
  [DataSyncEventType.Rail]: 'Rail',
  [DataSyncEventType.Rehandling]: 'Rehandling',
  [DataSyncEventType.Protocols]: 'Protocols'
};

export function getDataSyncEventTypeLabel(
  type: DataSyncEventType
): string {
  return DataSyncEventTypeLabels[type];
}
