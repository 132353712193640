import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of, withLatestFrom } from 'rxjs';

import { ReferenceDataService } from '@ifhms/common/angular/data-access/admin-api';
import { ContextService } from '@ifhms/common/angular/utils';
import { ReferenceDataType } from '@ifhms/models/admin';
import { SelectListItemWithTranslationDto } from '@ifhms/models/shared';

import { TagColorsActions } from './tag-colors.actions';

@Injectable()
export class TagColorsEffects {

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TagColorsActions.get),
      withLatestFrom(this.contextService.appContext$),
      exhaustMap(([, appContext]) => {
        return this.service.getSelectListByType(
          appContext,
          ReferenceDataType.TagColor
        ).pipe(
          map((items: SelectListItemWithTranslationDto[]) => {
            if (!items) TagColorsActions.error();
            return TagColorsActions.getSuccess({ items });
          }),
          catchError(() => of(TagColorsActions.error()))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private contextService: ContextService,
    private service: ReferenceDataService
  ) {}

}
