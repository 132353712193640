import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  AnimalTreatmentHistoryParams,
  CattleMovementParams,
  DailyTreatmentHistoryByFacilityParams,
  DefaultReportParams,
  EventDetailsReportParams,
  LotExpenseSummaryReportParams,
  LotHealthSummaryReportParams,
  LotOtherDiseasesDetailReportParams,
  LotUFDetailReportParams,
  OtherDiseasesManagementReportParams,
  PostMortemDetailByDateReportParams,
  PostMortemDetailReportParams,
  PostMortemEventReportParams,
  ProductOnHandReportParams,
  ProductUsageReportParams,
  RailDetailByDateReportParams,
  ReconciledPhysicalInventoryReportParams,
  TreatmentEventReportParams,
  UFManagementReportParams,
  WithdrawalReportParams,
  WorkOrdersReportParams,
  LotTreatmentHistoryParams
} from '../dtos';
import { AH_FMS_REPORT_API_URL } from '../tokens';

@Injectable()
export class ReportGenerationService {

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_REPORT_API_URL) private apiUrl: string
  ) {

  }

  getAnimalTreatmentHistory(params: AnimalTreatmentHistoryParams): Observable<any> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        ...params
      }
    };
    const options: object = {
      params: new HttpParams(httpParams),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/AnimalTreatmentHistory`, options);
  }

  getLotTreatmentHistory(params: LotTreatmentHistoryParams): Observable<any> {
    
    return this.http.put(`${this.apiUrl}/LotTreatmentReview`, params, { responseType: 'arraybuffer' });
  }

  getTreatmentEvent(params: TreatmentEventReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId ?? '',
            eventDate: params.eventDate ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/TreatmentEvent`, options);
  }

  getPostMortemEvent(params: PostMortemEventReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId ?? '',
            animalId: params.animalId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/PostMortemEvent`, options);
  }

  getCattleMovementReport(params: CattleMovementParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId ?? '',
            dateFrom: params.dateFrom ?? '',
            dateTo: params.dateTo ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/CattleMovement`, options);
  }

  getCurrentProductPricing(params: DefaultReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/CurrentProductPricing`, options);
  }

  getDailyTreatmentHistoryByFacilityReport(params: DailyTreatmentHistoryByFacilityParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId!,
            eventDate: params.eventDate!,
            facilityId: params.facilityId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/DailyTreatmentHistoryByFacility`, options);
  }

  getEventDetailsReport(params: EventDetailsReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId ?? '',
            type: params.workOrderType,
            workOrderId: params.workOrderId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/EventDetails`, options);
  }

  getHospitalOccupancyReport(params: DefaultReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/HospitalOccupancy`, options);
  }

  getLotExpenseSummaryReport(params: LotExpenseSummaryReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId!,
            lotId: params.lotId!,
            dateFrom: params.dateFrom ?? '',
            dateTo: params.dateTo ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/LotExpensesSummary`, options);
  }

  getLotHealthSummaryReport(params: LotHealthSummaryReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId!,
            lotId: params.lotId!
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/LotHealthSummary`, options);
  }

  getLotOtherDiseasesDetailReport(params: LotOtherDiseasesDetailReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId!,
            lotId: params.lotId!,
            penId: params.penId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/LotOtherDiseasesDetail`, options);
  }

  getLotUFDetailReport(params: LotUFDetailReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId,
            lotId: params.lotId,
            penId: params.penId || ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/LotUFDetail`, options);
  }

  getUFManagementReport(params: UFManagementReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId!,
            startsAfter: params.startedAfter ?? '',
            dateFrom: params.dateFrom ?? '',
            dateTo: params.dateTo ?? '',
            healthRiskCategoryId: params.healthRisk ?? '',
            sortBy: params.sortBy ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/UFManagement`, options);
  }

  getOtherDiseasesManagementReport(params: OtherDiseasesManagementReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId!,
            startsAfter: params.startedAfter ?? '',
            dateFrom: params.dateFrom ?? '',
            dateTo: params.dateTo ?? '',
            healthRiskCategoryId: params.healthRisk ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/OtherDiseasesManagement`, options);
  }

  getLotInventoryReport(params: DefaultReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/LotInventory`, options);
  }

  getPenInventoryReport(params: DefaultReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/PenInventory`, options);
  }

  getPostMortemDetailReport(params: PostMortemDetailReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId!,
            lotId: params.lotId!
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/PostMortemDetail`, options);
  }

  getPostMortemDetailByDateReport(params: PostMortemDetailByDateReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId!,
            dateFrom: params.dateFrom ?? '',
            dateTo: params.dateTo ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/PostMortemDetailDate`, options);
  }

  getRailDetailByDateReport(params: RailDetailByDateReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId!,
            dateFrom: params.dateFrom ?? '',
            dateTo: params.dateTo ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/RailDetailDate`, options);
  }

  getProductOnHandReport(params: ProductOnHandReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId,
            isProductsBelowMinimum: params.isProductsBelowMinimum
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/ProductOnHand`, options);
  }

  getProductUsageReport(params: ProductUsageReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId!,
            dateFrom: params.dateFrom!,
            dateTo: params.dateTo!,
            lotId: params.lotId ?? '',
            allLotsIndividual: params.allLotsIndividual
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/ProductUsage`, options);
  }

  getReconciledPhysicalInventoryReport(params: ReconciledPhysicalInventoryReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId!,
            reconciliationId: params.reconciliationId
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/ProductReconciliationInventoryValue`, options);
  }

  getTagAvailabilityReport(params: DefaultReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/TagAvailability`, options);
  }

  getWithdrawalReport(params: WithdrawalReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId!,
            lotId: params.lotId ?? '',
            lotSubGroupId: params.lotSubGroupId ?? '',
            penId: params.penId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/WithdrawalSummary`, options);
  }

  getWorkOrdersArrivalReport(params: WorkOrdersReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId ?? '',
            workOrderId: params.workOrderId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/ArrivalWorkOrder`, options);
  }

  getWorkOrdersLotTransferReport(params: WorkOrdersReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId ?? '',
            workOrderId: params.workOrderId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/LotTransferWorkOrder`, options);
  }

  getWorkOrdersMovementReport(params: WorkOrdersReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId ?? '',
            workOrderId: params.workOrderId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/MovementWorkOrder`, options);
  }

  getWorkOrdersRailReport(params: WorkOrdersReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId ?? '',
            workOrderId: params.workOrderId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/RailWorkOrder`, options);
  }

  getWorkOrdersRehandlingReport(params: WorkOrdersReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId ?? '',
            workOrderId: params.workOrderId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/RehandlingWorkOrder`, options);
  }

  getWorkOrdersShipmentReport(params: WorkOrdersReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId ?? '',
            workOrderId: params.workOrderId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/ShipmentWorkOrder`, options);
  }

  getWorkOrdersTransferOutReport(params: WorkOrdersReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId ?? '',
            workOrderId: params.workOrderId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/TransferOutWorkOrder`, options);
  }

  getWorkOrdersTransferInReport(params: WorkOrdersReportParams): Observable<any> {
    const options: object = {
      params: new HttpParams(
        {
          fromObject: {
            operationId: params.operationId ?? '',
            workOrderId: params.workOrderId ?? ''
          }
        }
      ),
      responseType: 'arraybuffer'
    };
    return this.http.get(`${this.apiUrl}/TransferInWorkOrder`, options);
  }

}
