import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  GroupCreateDto,
  GroupDto,
  GroupListItemDto,
  GroupUpdateDto
} from '@ifhms/models/admin';

import { AH_FMS_ADMIN_API_CONFIG } from '../../tokens';

@Injectable()
export class GroupManagementService {
  constructor(
    @Inject(AH_FMS_ADMIN_API_CONFIG) private apiUrl: string,
    private http: HttpClient
  ) {
  }

  getAllCompanyGroups(companyId: string): Observable<GroupListItemDto[]> {
    return this.http.get<GroupListItemDto[]>(`${this.apiUrl}/Groups/Company/${companyId}`);
  }

  create(companyId: string, groupCreate: GroupCreateDto): Observable<GroupListItemDto> {
    return this.http.post<GroupListItemDto>(`${this.apiUrl}/Groups?companyId=${companyId}`, groupCreate);
  }

  update(companyId: string, groupUpdate: GroupUpdateDto): Observable<GroupDto> {
    return this.http.put<GroupDto>(`${this.apiUrl}/Groups?companyId=${companyId}`, groupUpdate);
  }

  isNameUnique(companyId: string, groupName : string): Observable<boolean> {
    const httpParams: HttpParamsOptions = {
      fromObject: { groupName  }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<boolean>(`${this.apiUrl}/Groups/IsGroupNameValid?companyId=${companyId}`, options);
  }
}
