import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';

import { CommonUserProfileFacade } from '../+state';

@Injectable({ providedIn: 'root' })
export class CommonUserEnterpriseGuard {

  constructor(
    private readonly userProfileFacade: CommonUserProfileFacade,
    private readonly router: Router
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userProfileFacade.isEnterprise$.pipe(
      map((isEnterprise) => {
        if (!isEnterprise) {
          return this.router.parseUrl('/');
        }
        return true;
      })
    );
  }

}
