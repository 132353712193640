import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { getLoadedSelectItems } from '@common/angular/utils';

import { ProcurementCodesActions } from './procurement-codes.actions';
import * as reducer from './procurement-codes.reducer';
import { ProcurementCodesSelectors } from './procurement-codes.selectors';

@Injectable()
export class ProcurementCodesFacade {

  loaded$ = this.store.select(ProcurementCodesSelectors.selectLoaded);
  items$ = getLoadedSelectItems(this.store.select(ProcurementCodesSelectors.selectItems), this.loaded$);

  constructor(private store: Store<reducer.State>) {}
  
  get(): void {
    this.store.dispatch(ProcurementCodesActions.get());
  }

}
