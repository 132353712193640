import { Component, Input } from '@angular/core';

import { IconName } from '../interfaces';
import { SersiIconService } from '../services';

@Component({
  selector: 'sersi-icon',
  templateUrl: './sersi-icon.component.html',
  styleUrls: ['./sersi-icon.component.scss']
})
export class SersiIconComponent {
  @Input() classNames = '';

  iconDir: string;
  iconUrl: string;

  constructor(private sersiIconService: SersiIconService) {
    this.iconDir = this.sersiIconService.path;
  }

  @Input() set name(name: IconName) {
    this.iconUrl = `${this.iconDir + name}.svg`;
  }
}
