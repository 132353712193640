export enum DataSyncEventStatus {
  Pending = 1,
  Processing = 2,
  Completed = 3,
  Orphaned = 4,
  Failed = 5
}

export const DataSyncEventStatusLabels: Record<DataSyncEventStatus, string> = {
  [DataSyncEventStatus.Pending]: 'Pending',
  [DataSyncEventStatus.Processing]: 'Processing',
  [DataSyncEventStatus.Completed]: 'Completed',
  [DataSyncEventStatus.Orphaned]: 'Orphaned',
  [DataSyncEventStatus.Failed]: 'Failed'
};

export function getDataSyncEventStatusLabel(
  status: DataSyncEventStatus
): string {
  return DataSyncEventStatusLabels[status];
}
